import React from 'react';
import mountain from '../../Images/mountain.png';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';

export default class PMS extends React.Component {

	render() {

		return (

			<div className='body2'>
				<Helmet>
					<meta charSet="utf-8" />
					<title>Portfolio Management Services</title>
				</Helmet>

				<div className="container2 text-center">
					<br />
					<br />
					<div className="display-4">Portfolio Management Services</div>
					<br />
					<p className="lead">
						<a className="btn btn-success btn-lg page-scroll" href="/#contact" role="button">Talk to Expert</a>
					</p>
					<img src={mountain} alt="mountain" style={{ 'width': '100%' }} />
					<br />
					<br />
				</div>
				<div className="container">
					<div className='text-center'>
						<br />
						<br />
						<h5>Special investments option for individual looking for specialized investment avenue. Suited for High Networth Individuals with higher return expectation with higher degree of risk tolerance</h5>
					</div>
				</div>

				<section>
					<div className='container'>
						<h2 className="display-4 text-center">Advantages</h2>
						<br />
						<br />
						<div className="row">
							<ul>
								<li>Superior Return</li>
								<li>Customized Portfolio</li>
								<li>Professional and active Management</li>
								<li>Transparency</li>
								<li>Strong Risk  Management</li>
							</ul>
						</div>
					</div>
				</section>

				<div className='container-fluid learn_section body2'>
					<h2 className="display-4 text-center">Learn ...</h2>
					<h2 className='text-center'>~</h2>
					<br />
					<br />
					<div className="row text-center">
						<div className="col-md-4 col-sm-12">
							<span><Link to='/SipCalculator'>Know how much you will save through your SIP</Link></span>
						</div>
						<div className="col-md-4 col-sm-12">
							<span><Link to='/RetirementCalculator'>Plan your Retirement</Link></span>
						</div>
						<div className="col-md-4 col-sm-12">
							<span><Link to='/LifeInsuranceCalculator'>Know how much cover you need</Link></span>
						</div>
					</div>
					<div className="row text-center">
						<div className="col-md-4 col-sm-12">
							<span><Link to='/GoalCalculator'>How much I should invest to achieve my goal</Link></span>
						</div>
						<div className="col-md-4 col-sm-12">
							<span><Link to='/ChildEductionCalculator'>Plan your child’s education</Link></span>
						</div>
						<div className="col-md-4 col-sm-12">
						</div>
					</div>
					<div className="row text-center">
						<div className="col-md-4 col-sm-12">
							<span><Link to='/RiskProfileCalculator'>Evaluate Your Risk Profile</Link></span>
						</div>
						<div className="col-md-4 col-sm-12">
							<span><Link to='/FutureValueCalculator'>Know Future value</Link></span>
						</div>
						<div className="col-md-4 col-sm-12">
						</div>
					</div>
				</div>
			</div>
		)
	}
}
