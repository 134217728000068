import React from 'react';
import { AvForm, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import { Button, FormGroup,Card,CardBody} from 'reactstrap';
import './risk.css';

export default class RiskProfileCalculator extends React.Component{

constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {headline: ''};
  }

  handleSubmit(event, errors, values) {
    this.setState({errors, values});
    var score = (+this.state.values.current_age)+(+this.state.values.major_expenditure)+(+this.state.values.most_of_the_money)+(+this.state.values.expected_income)+(+this.state.values.investment_looses)+(+this.state.values.plans_to_invest)+(+this.state.values.invest_in_stock)+(+this.state.values.invest_in_bond)+(+this.state.values.finentially_responsible)+(+this.state.values.feeling_on_the_decision)
    
     this.setState({final:(score)},function(){
       console.log(this.state.final >= 60)
        if(this.state.final <= 50){
          this.setState({headline: 'Conservative'})
        }
        if(this.state.final >= 50 && this.state.final  <= 60){
          this.setState({headline: 'Moderate'})
        }
        if(this.state.final >= 60 && this.state.final <= 70){
          this.setState({headline: 'Moderately Aggressive'})
        }
        if(this.state.final > 70){
          this.setState({headline: 'Aggressive'})
        }
     })
  }
  
  render(){
    
const defaultValues = {
  current_age: '8',
  major_expenditure: '6',
  most_of_the_money: '6',
  expected_income: '10',
  investment_looses: '6',
  plans_to_invest: '6',
  invest_in_stock: '6',
  invest_in_bond: '6',
  finentially_responsible: '6',
  feeling_on_the_decision: '8'
};
    
    return (
<div className="body2">     
 <div className="container">
  <br/>
  <br/>
 <h2 className='text-center'>Calculate Your Risk Profile</h2>
  <br/>
   <Card className='card_size'>
     <CardBody>
        <AvForm onSubmit={this.handleSubmit} model={defaultValues}>
          <AvRadioGroup inline name="current_age" label="What is your age?" required>
            <AvRadio label="Less than 35" value="10" />
            <AvRadio label="36 - 56" value="8" />
            <AvRadio label="56 - 60" value="4" />
            <AvRadio label="More than 60" value="2" />
          </AvRadioGroup>
          <br/>
          <AvRadioGroup inline name="major_expenditure" label="What do you expect to be your next major expenditure?" required>
            <AvRadio label="Buying a house" value="2" />
            <AvRadio label="College Fee" value="4" />
            <AvRadio label="Capitalizing New Business" value="6" />
            <AvRadio label="Providing for Retirement" value="10" />
          </AvRadioGroup>
          <br/>
          <AvRadioGroup inline name="most_of_the_money" label="When do you want to use your most of the money" required>
            <AvRadio label="At any time" value="2" />
            <AvRadio label="In near future" value="4" />
            <AvRadio label="6-7 years" value="6" />
            <AvRadio label="10+ years" value="10" />
          </AvRadioGroup>
          <br/>
          <AvRadioGroup inline name="expected_income" label="In next couple of years you expect your income to" required>
            <AvRadio label="Stay same" value="6" />
            <AvRadio label="Moderate increase" value="8" />
            <AvRadio label="High increase" value="10" />
            <AvRadio label="Moderate decrease" value="4" />
            <AvRadio label="Highly decrease" value="2" />
          </AvRadioGroup>
          <br/>
          <AvRadioGroup name="investment_looses" label="Due to market correction your investment looses upto 14% just after you purchased it. What will you do?" required>
            <AvRadio label="Sell investment so that you don't have to worry for further decline" value="2" />
            <AvRadio label="Wait for some time to rise up again" value="6" />
            <AvRadio label="Buy more as at current rate its better than earlier" value="10" />
          </AvRadioGroup>
          <br/>
          <AvRadioGroup name="plans_to_invest" label="What will you choose to invest your money" required>
            <AvRadio label="Divide your portfolio among all types of available investment including low risk/ low return to high risk / high return" value="2" />
            <AvRadio label="Divide your portfolio in two investment with historically high return and moderate risk" value="6" />
            <AvRadio label="Invest in high return / high risk " value="10" />
          </AvRadioGroup>
          <br/>
          <AvRadioGroup name="invest_in_stock" label="Which one you choose to invest in stock?" required>
            <AvRadio label="Companies  that make significant technological advancement and are still below their initial offering price" value="10" />
            <AvRadio label="Established well known companies that have potential to grow continuously." value="6" />
            <AvRadio label="`Blue Chip` stocks that pay dividends" value="2" />
          </AvRadioGroup>
          <br/>
          <AvRadioGroup name="invest_in_bond" label="If you have option to invest in only one bond, what will you choose?" required>
            <AvRadio label="High Yield bond that pay higher rate of interest but having higher credit risk" value="10" />
            <AvRadio label="Bonds of well established company giving interest rate somewhere between other bonds" value="2" />
            <AvRadio label="Tax free bond " value="6" />
          </AvRadioGroup>
          <br/>
          <AvRadioGroup inline name="finentially_responsible" label="You are financially responsible for" required>
            <AvRadio label="Yourself" value="10" />
            <AvRadio label="One other person" value="8" />
            <AvRadio label="2-3 person other than yourself" value="6" />
            <AvRadio label="3-4 person other than yourself" value="4" />
            <AvRadio label="More than 4 person othar than yourself" value="2" />
          </AvRadioGroup>
          <br/>
          <AvRadioGroup inline name="feeling_on_the_decision" label="After you have made an investment, your feeling on the decision is" required>
            <AvRadio label="Exicited" value="10" />
            <AvRadio label="Satisfied" value="8" />
            <AvRadio label="Doubtful" value="4" />
            <AvRadio label="Sorry" value="2" />
          </AvRadioGroup>
        <br/>
         <FormGroup className='text-center'>
          <Button className="btn btn-success btn-lg page-scroll">Calculate</Button>
         </FormGroup>
      </AvForm>
       <br/>
         
         {this.state.values && <div className='text-center'>
         <h3> Your Risk Profile is "{this.state.headline}"</h3>
        </div>}
     </CardBody>
   </Card>
 </div>
</div>  

      
    )
  }
}