import React from 'react';
import {Card, CardBody,FormGroup, Label} from 'reactstrap';
import FormControl from 'react-bootstrap/lib/FormControl';
import CurrencyFormat from 'react-currency-format';

export default class RetirementCalculator extends React.Component{
  constructor() {
      super();
      this.state = {
        fields: {current_age:'35', age_of_retirement:'60', rate_of_inflation: '7', lumsum_amount:'4000000', investment_return:'12', monthly_income: '50000' },
        errors: {},
        Inflated_Amount: '45075029',
        monthly_Investment: '23753',        
      }
      this.handleChange = this.handleChange.bind(this);
    };

handleChange(e) {
  let fields = this.state.fields;
  fields[e.target.name] = e.target.value;
  this.setState(fields,function(){
      this.calculate();
  });
};
  
calculate = () => {
   
  var years = (this.state.fields.age_of_retirement - this.state.fields.current_age) * 12
  var inflation_multiple = 1 + (this.state.fields.rate_of_inflation / 1200)
  var power_fuction_for_rc = Math.pow(inflation_multiple, years)
  var retirement_Corpus = power_fuction_for_rc * (this.state.fields.monthly_income) 
  var annual_corpus = Math.round(retirement_Corpus) *12 
  var total_corpus_required = annual_corpus / (this.state.fields.rate_of_inflation / 100)
  console.log(total_corpus_required)
  var final_corpus_required = Math.round(total_corpus_required) - this.state.fields.lumsum_amount
  console.log(final_corpus_required)
  
  var rate_of_return = (this.state.fields.investment_return / 1200)
  var inflation_multiple_for_return = (1 + rate_of_return)
  var power_fuction_for_monthly_sip = Math.pow(inflation_multiple_for_return, years) - 1
  var Total_monthly_investment =  final_corpus_required / ((power_fuction_for_monthly_sip / rate_of_return) * inflation_multiple_for_return)
  
  
  this.setState({Inflated_Amount: Math.round(final_corpus_required)})
  this.setState({monthly_Investment: Math.round(Total_monthly_investment)})
}
  render(){
    
    return(
    
<div className='body2'>
 <div className='container'>
  <br/>
   <div className="form_style2">
   <div className="col-sm-12 ">
    <div className='text-center'>
      <h2 className="display-4 heading_font">Return from Investments</h2>
    </div>
    <br/>
    <br/>
    <Card >
     <CardBody>
       <FormGroup>
         <Label>Current Age</Label>
         <div className="range-slider text-center">
          <input className="range-slider__range" type="range"  min="17" max="70" name="current_age" onChange={this.handleChange} value={this.state.fields.current_age} id="range_slider"/>
          <input type="message" name="current_age" value={this.state.fields.current_age} className="range-slider__value" onChange={this.handleChange}/><span> Years</span>
         </div>
       </FormGroup>
        
       <FormGroup>
         <Label>At what age you will be retired?</Label>
         <div className="range-slider text-center">
          <input className="range-slider__range" type="range"  min="30" max="75" name="age_of_retirement" onChange={this.handleChange} value={this.state.fields.age_of_retirement} id="range_slider"/>
          <input type="message" name="age_of_retirement" value={this.state.fields.age_of_retirement} className="range-slider__value" onChange={this.handleChange}/><span> Years</span>
         </div>
       </FormGroup>
        
       <FormGroup>
         <Label>How much monthly income you want if you retire today?</Label>
         <FormControl type="message" name="monthly_income" onChange={this.handleChange} value={this.state.fields.monthly_income}/>
         <div className="errorMsg">{this.state.errors.monthly_income}</div>
       </FormGroup>
    
       <FormGroup>  
         <Label>Lumsum amount you will receive at time of retirement</Label> 
          <FormControl type="message" name="lumsum_amount" onChange={this.handleChange} value={this.state.fields.lumsum_amount}/>
          <div className="errorMsg">{this.state.errors.lumsum_amount}</div>
       </FormGroup>     
       
       <FormGroup>
        <Label>Rate of Inflation</Label>
         <div className="range-slider text-center">
           <input className="range-slider__range" type="range"  min="1" max="25" name="rate_of_inflation" onChange={this.handleChange} value= {this.state.fields.rate_of_inflation} id="range_slider"/>
           <input type="message" name="rate_of_inflation" value={this.state.fields.rate_of_inflation} className="range-slider__value" onChange={this.handleChange}/><span> %</span>
         </div>
       </FormGroup> 
       
       <FormGroup>
        <Label>Investment Return</Label>
         <div className="range-slider text-center">
          <input className="range-slider__range" type="range"  min="1" max="25" name="investment_return" onChange={this.handleChange} value= {this.state.fields.investment_return} id="range_slider"/>
          <input type="message" name="investment_return" value={this.state.fields.investment_return} className="range-slider__value" onChange={this.handleChange}/><span> %</span>
         </div>
       </FormGroup> 
       <div>
          {(this.state.Inflated_Amount < 0) ?
              <h2>You have sufficient corpus</h2>
              :
            <div> 
            <CurrencyFormat value={this.state.Inflated_Amount} displayType={'text'} allowNegative={false} thousandSeparator={true} thousandSpacing='2s' prefix={'₹'} renderText={value => <div><h2>Corpus Required : {value}</h2></div>}/>
            
           <CurrencyFormat value={this.state.monthly_Investment} displayType={'text'} allowNegative={false} thousandSeparator={true} thousandSpacing='2s' prefix={'₹'} renderText={value => <div><h2>Monthly SIP required : {value}</h2></div>}/>
          </div>   
           }
      </div>  
     </CardBody>
    </Card>  
    <br/>
    <br/>
    </div>
   </div>
  </div>
 </div>  
    )
  }
}