import React from 'react';
import {Button,FormGroup, Label, Input} from 'reactstrap';
import './funds.css';
import $ from 'jquery';
import axios from 'axios';
import NotificationSystem from 'react-notification-system';
import InfiniteSections from 'react-infinite-sections';

var style = {
  NotificationItem: { 
    DefaultStyle: { 
     fontSize:'20px'
    }
  }
}

export default class HealthInsuranceForm extends React.Component{

_notificationSystem: null

addNotification(message){
    this._notificationSystem.addNotification({
      message: 'We Will Get Back To You Soon',
      level: 'success'
    });
	}	
	
constructor() {
      super();
      this.state = {
        fields: {age:'25', finalAmount:'5', username:'',emailid:'', mobileno:'',city: '',comment:'', subject: 'Mutual Funds'},
        errors: {}
      }
      this.handleChange = this.handleChange.bind(this);
      this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
    };

    handleChange(e) {
      let fields = this.state.fields;
      fields[e.target.name] = e.target.value;
      this.setState({
        fields
      });
    };

	  handleAgeChange(e) {
      let fields = this.state.fields;
      fields['age'] = e.target.value;
      this.setState({
        fields
      });
    };
	
		 handleAnnualAmountChange(e) {
      let fields = this.state.fields;
      fields['finalAmount'] = e.target.value;
      this.setState({
        fields
      });
    };
	
    submituserRegistrationForm(e) {
				var data = {
						contact : {
							name:this.state.fields.username,
							email:this.state.fields.emailid,
							mobile_No:this.state.fields.mobileno,
							city:this.state.fields.city,
							age:this.state.fields.age,
							final_amount:this.state.fields.finalAmount,
							message:this.state.fields.comment,
              subject: this.state.fields.subject
						}
	    	}
      e.preventDefault();
          let fields = {};
          fields["username"] = "";
          fields["emailid"] = "";
          fields["mobileno"] = "";
				  fields["city"] = "";
				  fields["comment"]="";
          fields["finalAmount"]= "5";
          fields["age"]="25"
          this.setState({fields:fields});
    
				axios({
					 method: 'post',
					 url: (process.env.REACT_APP_API_URL) + '/contacts',
					 data: data,
					 dataType: 'json'
				 })
					.then(function (response) {
						if(response.status === 200){
            this.addNotification(response.message);
						}
					}.bind(this))
					.catch(function (error) {
						console.log(error);
					});
     }


componentDidMount(){

	this._notificationSystem = this.refs.notificationSystem;
	
	var rangeSlider = function(){
  var slider = $('.range-slider'),
      range = $('.range-slider__range'),
      value = $('.range-slider__value');

  slider.each(function(){

    value.each(function(){
      var value = $(this).prev().attr('value');
      $(this).html(value);
    });

    range.on('input', function(){
      $(this).next(value).html(this.value);
    });
  });
};

rangeSlider();

}

  render() {
    return (
      <div className="body2">
        <div className="container">
          <br />
          <div id="main-registration-container">
            <div id="register">
              <div className='col-sm-12'>
                <h2 className='text-center display-4 heading_font'>Enquire for Mutual Funds</h2>
                <br />
                <br />
                <InfiniteSections
                  className="settings-wrapper"
                  animate={true}
                  root={{ section: 'root', id: 'incomeDetails' }}
                  sections={dispatch => {
                    return {
                      root: [
                        {
                          id: 'incomeDetails',
                          component: (
                            <div>
                              <div className='form-body'>
                                <div className="header">
                                  <h3 className='text-center'>Income Details</h3>
                                  <FormGroup>
                                    <Label for="exampleCheckbox">Annual Earning</Label>
                                    <div className="range-slider text-center">
                                      <input className="range-slider__range" type="range" min="5" max="100" name="finalAmount" onChange={this.handleChange} value={this.state.fields.finalAmount} />
                                      <input type="message" onChange={this.handleAnnualAmountChange.bind(this)} value={this.state.fields.finalAmount} className="range-slider__value" /><span> Lakhs</span>
                                    </div>
                                  </FormGroup>
                                </div>
                              </div>
                              <div align="right">
                                {this.state.fields.finalAmount.match(/^[a-zA-Z ]*$/) || this.state.fields.finalAmount === '' ?
                                  null
                                  :
                                  <Button className="link btn btn-success" onClick={dispatch('personal', 'personalDetails')}>Next</Button>
                                }
                              </div>
                            </div>
                          )
                        }
                      ],
                      personal: [
                        {
                          id: 'personalDetails',
                          component: (
                            <div>
                              <div className="section-wrapper2">
                                <div className="header">
                                  <h3 className='text-center'>Personal Details</h3>
                                  <FormGroup>
                                    <Label for="exampleEmail">Name</Label>
                                    <Input type="message" name="username" value={this.state.fields.username} onChange={this.handleChange}></Input>
                                  </FormGroup>

                                  <FormGroup>
                                    <Label for="exampleEmail">Email ID</Label>
                                    <Input type="email" name="emailid" value={this.state.fields.emailid} onChange={this.handleChange}></Input>
                                  </FormGroup>

                                  <FormGroup>
                                    <Label for="exampleEmail">Mobile No</Label>
                                    <Input type="message" name="mobileno" value={this.state.fields.mobileno} onChange={this.handleChange}></Input>
                                  </FormGroup>

                                  <FormGroup>
                                    <Label for="exampleEmail">City</Label>
                                    <Input type="message" name="city" value={this.state.fields.city} onChange={this.handleChange}></Input>
                                  </FormGroup>
                                </div>
                              </div>
                              <div className="link">
                                <Button className="btn btn-success" onClick={dispatch('root', 'incomeDetails', true)}>Back</Button>
                                {this.state.fields.username.match(/^[0-9]+$/) || this.state.fields.emailid.match(/^([\w.%+-]+)@([\w-]+\.)$/) || this.state.fields.mobileno.match(/^[a-zA-Z ]*$/) || this.state.fields.city.match(/^[0-9]+$/) || this.state.fields.username === '' || this.state.fields.emailid === '' || this.state.fields.mobileno === '' || this.state.fields.city === '' ?
                                  null
                                  :
                                  <Button className="pull-right btn btn-success" onClick={dispatch('age', 'person_age')}>Next</Button>
                                }
                              </div>
                            </div>
                          )
                        }
                      ],
                      age: [
                        {
                          id: 'person_age',
                          component: (
                            <div>
                              <div className='form-body'>
                                <div className="header">
                                  <h3 className='text-center'>Age Details</h3>
                                  <FormGroup>
                                    <Label for="exampleCheckbox">{this.state.title}</Label>
                                    <div className="range-slider text-center">
                                      <input className="range-slider__range" type="range" min="18" max="70" name="age" onChange={this.handleChange} value={this.state.fields.age} />
                                      <input type="message" onChange={this.handleAgeChange.bind(this)} value={this.state.fields.age} className="range-slider__value" /><span> Years</span>
                                    </div>
                                  </FormGroup>
                                </div>
                              </div>
                              <div className="link">
                                <Button className="btn btn-success" onClick={dispatch('personal', 'personalDetails', true)}>Back</Button>
                                {this.state.fields.age.match(/^[a-zA-Z ]*$/) || this.state.fields.age === '' ?
                                  null
                                  :
                                  <Button className="pull-right btn btn-success" onClick={dispatch('comment', 'any_comment')}>Next</Button>
                                }
                              </div>
                            </div>
                          )
                        }
                      ],
                      comment: [
                        {
                          id: 'any_comment',
                          component: (
                            <div>
                              <div className='form-body'>
                                <div className="header">
                                  <FormGroup>
                                    <Label for="exampleEmail">Comment</Label>
                                    <Input type="message" name="comment" value={this.state.fields.comment} onChange={this.handleChange} style={{ 'height': '130px' }}></Input>
                                  </FormGroup>
                                </div>
                              </div>
                              <div className="link">
                                <Button className="btn btn-success" onClick={dispatch('age', 'person_age', true)}>Back</Button>
                                <Button className="pull-right btn btn-success" onClick={this.submituserRegistrationForm}>Send</Button>
                              </div>
                            </div>
                          )
                        }
                      ]
                    };
                  }}
                />
            </div>
          </div>
        </div>
      <NotificationSystem ref="notificationSystem" style={style}/>
		  <br/>
	  </div>
  </div>
				

      );
  }


}