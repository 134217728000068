import React from 'react';
import Construction from '../../Images/Construction.svg'

export default class ComingSoon extends React.Component{
	
	render(){
		
		return(
		  <div className='body2'>
			    <img src={Construction} alt='coming_soon' style={{'width': '100%'}} />
			</div>
		)
	}
}