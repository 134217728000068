import React from 'react';
import './sip.css';

import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';

import FormControl from 'react-bootstrap/lib/FormControl';
import CurrencyFormat from 'react-currency-format';
import $ from 'jquery';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';


export default class SipCalculator extends React.Component {

  constructor() {
    super();
    this.state = {
      fields: { rate: '12', year: '10', principal: '10000' },
      errors: {},
      amount: '2323391'
    }
    this.handleChange = this.handleChange.bind(this);
  };

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState(fields, function () {
      this.calculateSip();
    }.bind(this));
  }

  validateForm() {

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["rate"]) {
      formIsValid = false;
      errors["rate"] = "*Please enter Rate of Interest.";
    }

    if (typeof fields["rate"] !== "undefined") {
      if (fields["rate"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["rate"] = "*Please enter numbers only";
      }
    }

    if (!fields["year"]) {
      formIsValid = false;
      errors["year"] = "*Please enter Number of years.";
    }

    if (typeof fields["year"] !== "undefined") {
      if (fields["year"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["year"] = "*Please enter numbers only";
      }
    }

    this.setState({
      errors: errors
    });
    return formIsValid;

  }

  calculateSip() {

    if (this.validateForm()) {
      var rate_of_return = (this.state.fields.rate) / 1200
      var time = ((this.state.fields.year) * 12) + 1
      var inflation_multiple = (1 + rate_of_return)
      var power_fuction = Math.pow(inflation_multiple, time) - 1
      var final = ((power_fuction / rate_of_return) * this.state.fields.principal) - this.state.fields.principal
      this.setState({ amount: Math.round(final) })
    }
  }

  handleRange(e) {
    let fields = this.state.fields;
    fields['principal'] = e.target.value;
    this.setState(fields, function () {
      this.calculateSip();
    }.bind(this));
  }

  componentDidMount() {
    var rangeSlider = function () {
      var slider = $('.range-slider'),
        range = $('.range-slider__range'),
        value = $('.range-slider__value');

      slider.each(function () {

        value.each(function () {
          var value = $(this).prev().attr('value');
          $(this).html(value);
        });

        range.on('input', function () {
          $(this).next(value).html(this.value);
        });
      });
    };
    rangeSlider();

  }

  render() {

    const data = [{ name: 'Investment', value: (this.state.fields.principal) * 12 * (this.state.fields.year) }, { name: 'Gain', value: ((this.state.amount) - ((this.state.fields.principal) * 12 * (this.state.fields.year))) }];
    const COLORS = ['rgb(125, 115, 210)', '#332ba9'];

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
      const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);

      return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
          {`${(percent * 100).toFixed(0)}%`}
        </text>
      );
    };

    return (

      <div className='body2'>
        <div className='container'>
          <br />
          <div align="center" id="calculator">
            <div>
              <h2>Future value of your monthly SIP</h2>
            </div>
            <br />
            <br />
            <br />
            <Row>
              <Col md={6} sm={6} xs={12}>
                <div className="range-slider text-center col-md-12 col-sm-12 col-xs-12">
                  <input className="range-slider__range" type="range" min="1000" max="100000" step='1000' name="principal" onChange={this.handleChange} value={this.state.fields.principal} id="range_slider" />
                  <input type="message" onChange={this.handleRange.bind(this)} value={this.state.fields.principal} className="range-slider__value3" />
                  <h3>SIP Amount</h3>
                </div>
                <FormControl type="message" name="rate" placeholder="Rate *" onChange={this.handleChange} value={this.state.fields.rate} />
                <h3>Rate of Return</h3>
                <div className="errorMsg">{this.state.errors.rate}</div>
                <br />
                <br />
                <FormControl type="message" name="year" placeholder="Year *" onChange={this.handleChange} value={this.state.fields.year} />
                <h3>Investment Duration (Years)</h3>
                <div className="errorMsg">{this.state.errors.year}</div>
              </Col>
              <Col md={6} sm={6} xs={12}>
                <div className='d-none d-lg-block'>
                  <PieChart width={450} height={292}>
                    <Pie
                      data={data}
                      dataKey="value"
                      labelLine={false}
                      label={renderCustomizedLabel}
                      outerRadius={140}
                      fill="#8884d8"
                    >
                      {
                        data.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]} />)
                      }
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </div>
                <div className='d-none d-md-block d-lg-none'>
                  <PieChart width={320} height={292}>
                    <Pie
                      data={data}
                      dataKey="value"
                      labelLine={false}
                      label={renderCustomizedLabel}
                      outerRadius={140}
                      fill="#8884d8"
                    >
                      {
                        data.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]} />)
                      }
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </div>
                <div className='d-none d-sm-block d-md-none'>
                  <PieChart width={250} height={250}>
                    <Pie
                      data={data}
                      dataKey="value"
                      labelLine={false}
                      label={renderCustomizedLabel}
                      outerRadius={120}
                      fill="#8884d8"
                    >
                      {
                        data.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]} />)
                      }
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </div>
                <div className='d-block d-sm-none'>
                  <PieChart width={250} height={250}>
                    <Pie
                      data={data}
                      dataKey="value"
                      labelLine={false}
                      label={renderCustomizedLabel}
                      outerRadius={120}
                      fill="#8884d8"
                    >
                      {
                        data.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]} />)
                      }
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </div>
                {(this.state.fields.rate !== '' && this.state.fields.year !== '') ?
                  <CurrencyFormat value={this.state.amount} displayType={'text'} allowNegative={false} thousandSeparator={true} thousandSpacing='2s' prefix={'₹'} renderText={value => <div><h3>Future Value of your SIP Investment : {value}</h3></div>} />
                  :
                  <h3>Future Value of your SIP Investment: ₹</h3>
                }
                <br />
              </Col>
            </Row>
            <br />
            <br />
          </div>
        </div>
      </div>

    );
  }
}




