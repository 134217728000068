import React from 'react';
import {CardBody, FormGroup, Label } from 'reactstrap';
import FormControl from 'react-bootstrap/lib/FormControl';
import CurrencyFormat from 'react-currency-format';

export default class ChildEducationCalculator extends React.Component {

  constructor() {
    super();
    this.state = {
      fields: { child_age: '5', age_to_start_course: '18', current_price: '2000000', rate_of_inflation: '8', saved_amount: '0', rate_of_return: '12', second_child_age: '3', second_chid_age_to_start_course: '18', second_current_price: '2500000', second_rate_of_inflation: '8', saved_amount_for_second_child: '200000', second_rate_of_return: '12', third_child_age: '2', third_chid_age_to_start_course: '19', third_current_price: '2500000', third_rate_of_inflation: '8', third_rate_of_return: "13", saved_amount_for_third_child: '300000', fourth_child_age: '3', fourth_chid_age_to_start_course: '20', fourth_current_price: '2000000', fourth_rate_of_inflation: '12', fourth_rate_of_return: '10', saved_amount_for_fourth_child: '100000' },
      errors: {},
      detail_box_for_second_child: true,
      detail_box_for_third_child: true,
      detail_box_for_fourth_child: true,

      resuls_box_for_first_child: false,
      resuls_box_for_second_child: true,
      resuls_box_for_third_child: true,

      link_for_2nd_detail_box: false,
      link_for_3rd_detail_box: true,
      link_for_4th_detail_box: true,

      amount_for_first_child: '15,000',
      amount_for_second_child: '15,988',
      amount_for_third_child: '12,576',
      amount_for_fourth_child: '28,184'
    }
    this.handleChange = this.handleChange.bind(this);
  };

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState(fields, function () {
      if (this.validateForm()) {
        this.calculateForOneChild();
        this.calculateForTwoChild();
        this.calculateForThreeChild();
        this.calculateForFourthChild();
      }
    });
  };

  validateForm() {

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["current_price"]) {
      formIsValid = false;
      errors["current_price"] = "*Please enter current price of education";
    }

    if (typeof fields["current_price"] !== "undefined") {
      if (fields["current_price"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["current_price"] = "*Please enter numbers only";
      }
    }

    if (!fields["saved_amount"]) {
      formIsValid = false;
      errors["saved_amount"] = "*Please enter saved amount for child education";
    }

    if (typeof fields["saved_amount"] !== "undefined") {
      if (fields["saved_amount"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["saved_amount"] = "*Please enter numbers only";
      }
    }

    if (!fields["second_current_price"]) {
      formIsValid = false;
      errors["second_current_price"] = "*Please enter current price of education";
    }

    if (typeof fields["second_current_price"] !== "undefined") {
      if (fields["second_current_price"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["second_current_price"] = "*Please enter numbers only";
      }
    }

    if (!fields["saved_amount_for_second_child"]) {
      formIsValid = false;
      errors["saved_amount_for_second_child"] = "*Please enter saved amount for child education";
    }

    if (typeof fields["saved_amount_for_second_child"] !== "undefined") {
      if (fields["saved_amount_for_second_child"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["saved_amount_for_second_child"] = "*Please enter numbers only";
      }
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  calculateForOneChild = () => {
    var rate_of_inflation = (this.state.fields.rate_of_inflation) / 1200 
    var time = (this.state.fields.age_to_start_course) - (this.state.fields.child_age)
    var inflation_multiple_for_inflation = (1 + rate_of_inflation)
    var number_of_month_for_inflation = (12 * time)
    var power_fuction_for_inflation = Math.pow(inflation_multiple_for_inflation, number_of_month_for_inflation)
    var amount_from_rate_of_inflation = this.state.fields.current_price * power_fuction_for_inflation 
    console.log(amount_from_rate_of_inflation)

    var minus_saved_amount = this.state.fields.saved_amount - Math.floor(amount_from_rate_of_inflation)
    console.log(minus_saved_amount)
    
    var rate_of_return = (this.state.fields.rate_of_return) / 1200
    var inflation_multiple_for_return = (1 + rate_of_return)
    var number_of_month_for_return = (12 * time)
    var power_fuction_for_return = Math.pow(inflation_multiple_for_return, number_of_month_for_return) - 1
    var amount_from_rate_of_return =  minus_saved_amount / ((power_fuction_for_return / rate_of_return) * inflation_multiple_for_return)

    this.setState({ amount_for_first_child: Math.round(amount_from_rate_of_return) })
  }

  calculateForTwoChild = () => {
    var rate_of_inflation_for_second_child = (this.state.fields.second_rate_of_inflation) / 1200
    var time_for_second_child = (this.state.fields.second_chid_age_to_start_course) - (this.state.fields.second_child_age)
    var inflation_multiple_for_inflation_for_second_child = (1 + rate_of_inflation_for_second_child)
    var number_of_month_for_inflation_for_second_child = (12 * time_for_second_child)
    var power_fuction_for_inflation_for_second_child = Math.pow(inflation_multiple_for_inflation_for_second_child, number_of_month_for_inflation_for_second_child)
    var amount_form_rate_of_inflation_for_second_child = this.state.fields.second_current_price * power_fuction_for_inflation_for_second_child 
    
    var remove_saved_amount_for_second_child = this.state.fields.saved_amount_for_second_child - amount_form_rate_of_inflation_for_second_child

    var rate_of_return_for_second_child = (this.state.fields.second_rate_of_return) / 1200
    var inflation_multiple_for_return_for_second_child = (1 + rate_of_return_for_second_child)
    var number_of_month_for_return_for_second_child = (12 * time_for_second_child)
    var power_fuction_for_return_for_second_child = Math.pow(inflation_multiple_for_return_for_second_child, number_of_month_for_return_for_second_child) - 1
    var amount_from_rate_of_return_for_second_child = remove_saved_amount_for_second_child / ((power_fuction_for_return_for_second_child / rate_of_return_for_second_child) * inflation_multiple_for_return_for_second_child)

    var goal2 = amount_from_rate_of_return_for_second_child

    this.setState({ amount_for_second_child: Math.round(goal2)})
  }

  calculateForThreeChild = () => {

    var rate_of_inflation_for_third_child = (this.state.fields.third_rate_of_inflation) / 1200
    var time_for_third_child = (this.state.fields.third_chid_age_to_start_course) - (this.state.fields.third_child_age)

    var inflation_multiple_for_inflation_for_third_child = (1 + rate_of_inflation_for_third_child)
    var number_of_month_for_inflation_for_third_child = (12 * time_for_third_child)
    var power_fuction_for_inflation_for_third_child = Math.pow(inflation_multiple_for_inflation_for_third_child, number_of_month_for_inflation_for_third_child)
    var amount_form_rate_of_inflation_for_third_child = this.state.fields.third_current_price * power_fuction_for_inflation_for_third_child 
    
    var remove_saved_amount_for_third_child = this.state.fields.saved_amount_for_third_child - amount_form_rate_of_inflation_for_third_child
    
    var rate_of_return_for_third_child = (this.state.fields.third_rate_of_return) / (1200)
    var inflation_multiple_for_return_for_third_child = (1 + rate_of_return_for_third_child)
    var number_of_month_for_return_for_third_child = (12 * time_for_third_child)
    var power_fuction_for_return_for_third_child = Math.pow(inflation_multiple_for_return_for_third_child, number_of_month_for_return_for_third_child) - 1
    var amount_from_rate_of_return_for_third_child = remove_saved_amount_for_third_child / ((power_fuction_for_return_for_third_child / rate_of_return_for_third_child) * inflation_multiple_for_return_for_third_child )

    var goal3 = amount_from_rate_of_return_for_third_child

    this.setState({amount_for_third_child: Math.round(goal3)})
  }

  calculateForFourthChild = () => {
   
    var rate_of_inflation_for_fourth_child = (this.state.fields.fourth_rate_of_inflation) / 1200
    var time_for_fourth_child = (this.state.fields.fourth_chid_age_to_start_course) - (this.state.fields.fourth_child_age)

    var inflation_multiple_for_inflation_for_fourth_child = (1 + rate_of_inflation_for_fourth_child)
    var number_of_month_for_inflation_for_fourth_child = (12 * time_for_fourth_child)
    var power_fuction_for_inflation_for_fourth_child = Math.pow(inflation_multiple_for_inflation_for_fourth_child, number_of_month_for_inflation_for_fourth_child)
    var amount_form_rate_of_inflation_for_fourth_child = this.state.fields.fourth_current_price * power_fuction_for_inflation_for_fourth_child
    
    var remove_saved_amount_for_fourth_child = this.state.fields.saved_amount_for_fourth_child - amount_form_rate_of_inflation_for_fourth_child

    var rate_of_return_for_fourth_child = (this.state.fields.fourth_rate_of_return) / (1200)
    var inflation_multiple_for_return_for_fourth_child = (1 + rate_of_return_for_fourth_child)
    var number_of_month_for_return_for_fourth_child = (12 * time_for_fourth_child)
    var power_fuction_for_return_for_fourth_child = Math.pow(inflation_multiple_for_return_for_fourth_child, number_of_month_for_return_for_fourth_child) - 1
    var amount_from_rate_of_return_for_fourth_child = remove_saved_amount_for_fourth_child / ((power_fuction_for_return_for_fourth_child / rate_of_return_for_fourth_child) * inflation_multiple_for_return_for_fourth_child)

    var goal4 = amount_from_rate_of_return_for_fourth_child

    this.setState({ amount_for_fourth_child: Math.round(goal4)})

  }

  hideFieldForFirstChild = (e) => {
    e.preventDefault();
    this.setState({ detail_box_for_second_child: false, resuls_box_for_first_child: true, link_for_2nd_detail_box: true, link_for_3rd_detail_box: false, resuls_box_for_second_child: false }, function () {
      this.calculateForOneChild();
    })
  }

  hideFieldForsecondChild = (e) => {
    e.preventDefault();
    this.setState({ detail_box_for_third_child: false, resuls_box_for_second_child: true, link_for_3rd_detail_box: true, resuls_box_for_third_child: false, link_for_4th_detail_box: false }, function () {
      this.calculateForTwoChild();
    })
  }

  hideFieldThirdChild = (e) => {
    e.preventDefault();
    this.setState({ detail_box_for_fourth_child: false, resuls_box_for_third_child: true, link_for_4th_detail_box: true }, function () {
      this.calculateForThreeChild();
    })
  }

  render() {

    return (

      <div className='body2'>
        <div className='container'>
          <br />
          <div className="form_style2">
            <div className="col-sm-12 ">
              <div className='text-center'>
                <h2 className="display-4 heading_font">SIP For Your Child's Education</h2>
              </div>
              <br />
              <br />
              <div className="child_calulator_card">
                <CardBody>
                  <FormGroup>
                    <Label>Age of Child</Label>
                    <div className="range-slider text-center">
                      <input className="range-slider__range" type="range" min="1" max="18" name="child_age" onChange={this.handleChange} value={this.state.fields.child_age} id="range_slider" />
                      <input type="message" name="child_age" value={this.state.fields.child_age} className="range-slider__value" onChange={this.handleChange} /><span> Years</span>
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <Label>Child age at the time of higher study</Label>
                    <div className="range-slider text-center">
                      <input className="range-slider__range" type="range" min="1" max="24" name="age_to_start_course" onChange={this.handleChange} value={this.state.fields.age_to_start_course} id="range_slider" />
                      <input type="message" name="age_to_start_course" value={this.state.fields.age_to_start_course} className="range-slider__value" onChange={this.handleChange} /><span> Years</span>
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <Label>Cost of Education as Present Value</Label>
                    <FormControl type="message" name="current_price" onChange={this.handleChange} value={this.state.fields.current_price} />
                    <div className="errorMsg">{this.state.errors.current_price}</div>
                  </FormGroup>

                  <FormGroup>
                    <Label>Education Inflation</Label>
                    <div className="range-slider text-center">
                      <input className="range-slider__range" type="range" min="1" max="25" name="rate_of_inflation" onChange={this.handleChange} value={this.state.fields.rate_of_inflation} id="range_slider" />
                      <input type="message" name="rate_of_inflation" value={this.state.fields.rate_of_inflation} className="range-slider__value" onChange={this.handleChange} /><span> %</span>
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <Label>Funds available from existing Savings at the Time of Child’s Higher Education</Label>
                    <FormControl type="message" name="saved_amount" onChange={this.handleChange} value={this.state.fields.saved_amount} />
                    <div className="errorMsg">{this.state.errors.saved_amount}</div>
                  </FormGroup>

                  <FormGroup>
                    <Label>Expected Return</Label>
                    <div className="range-slider text-center">
                      <input className="range-slider__range" type="range" min="1" max="25" name="rate_of_return" onChange={this.handleChange} value={this.state.fields.rate_of_return} id="range_slider" />
                      <input type="message" name="rate_of_return" value={this.state.fields.rate_of_return} className="range-slider__value" onChange={this.handleChange} /><span> %</span>
                    </div>
                  </FormGroup>
                </CardBody>
              </div>
              <div hidden={this.state.link_for_2nd_detail_box}>
                <br />
                <br />
                <span className='child_span' onClick={this.hideFieldForFirstChild.bind(this)}>Add Detail For Another Child</span>
              </div>
              <br />
              <div hidden={this.state.resuls_box_for_first_child}>
                {(this.state.fields.child_age === this.state.fields.age_to_start_course) ?
                  <h2>Your Are Late</h2>
                  :
                  (this.state.amount_for_first_child > 0 ) ?
                    <h2>You Are Secured</h2>
                    :
                    <CurrencyFormat value={this.state.amount_for_first_child} displayType={'text'} allowNegative={false} thousandSeparator={true} thousandSpacing='2s' prefix={'₹'} renderText={value => <div><h2>Your monthly SIP should be : {value}</h2></div>} />
                }
              </div>

              <div hidden={this.state.detail_box_for_second_child}>
                <div className="child_calulator_card">
                  <CardBody>
                    <FormGroup>
                      <Label>Age of Child</Label>
                      <div className="range-slider text-center">
                        <input className="range-slider__range" type="range" min="1" max="18" name="second_child_age" onChange={this.handleChange} value={this.state.fields.second_child_age} id="range_slider" />
                        <input type="message" name="second_child_age" value={this.state.fields.second_child_age} className="range-slider__value" onChange={this.handleChange} /><span> Years</span>
                      </div>
                    </FormGroup>

                    <FormGroup>
                      <Label>Child age at the time of higher study</Label>
                      <div className="range-slider text-center">
                        <input className="range-slider__range" type="range" min="1" max="24" name="second_chid_age_to_start_course" onChange={this.handleChange} value={this.state.fields.second_chid_age_to_start_course} id="range_slider" />
                        <input type="message" name="second_chid_age_to_start_course" value={this.state.fields.second_chid_age_to_start_course} className="range-slider__value" onChange={this.handleChange} /><span> Years</span>
                      </div>
                    </FormGroup>

                    <FormGroup>
                      <Label>Cost of Education as Present Value</Label>
                      <FormControl type="message" name="second_current_price" onChange={this.handleChange} value={this.state.fields.second_current_price} />
                      <div className="errorMsg">{this.state.errors.second_current_price}</div>
                    </FormGroup>

                    <FormGroup>
                      <Label>Education Inflation</Label>
                      <div className="range-slider text-center">
                        <input className="range-slider__range" type="range" min="1" max="25" name="second_rate_of_inflation" onChange={this.handleChange} value={this.state.fields.second_rate_of_inflation} id="range_slider" />
                        <input type="message" name="second_rate_of_inflation" value={this.state.fields.second_rate_of_inflation} className="range-slider__value" onChange={this.handleChange} /><span> %</span>
                      </div>
                    </FormGroup>

                    <FormGroup>
                      <Label>Funds available from existing Savings at the Time of Child’s Higher Education</Label>
                      <FormControl type="message" name="saved_amount_for_second_child" onChange={this.handleChange} value={this.state.fields.saved_amount_for_second_child} />
                      <div className="errorMsg">{this.state.errors.saved_amount_for_second_child}</div>
                    </FormGroup>

                    <FormGroup>
                      <Label>Expected Return</Label>
                      <div className="range-slider text-center">
                        <input className="range-slider__range" type="range" min="1" max="25" name="second_rate_of_return" onChange={this.handleChange} value={this.state.fields.second_rate_of_return} id="range_slider" />
                        <input type="message" name="second_rate_of_return" value={this.state.fields.second_rate_of_return} className="range-slider__value" onChange={this.handleChange} /><span> %</span>
                      </div>
                    </FormGroup>
                  </CardBody>
                </div>
                <div hidden={this.state.link_for_3rd_detail_box}>
                  <br />
                  <br />
                  <span className='child_span' onClick={this.hideFieldForsecondChild.bind(this)}>Add Detail For Another Child</span>
                </div>
                <br />
                <div hidden={this.state.resuls_box_for_second_child}>
                  {(this.state.amount_for_first_child > 0) ?
                    <h2>Your Are Secured</h2>
                    :
                    <CurrencyFormat value={this.state.amount_for_first_child} displayType={'text'} allowNegative={false} thousandSeparator={true} thousandSpacing='2s' prefix={'₹'} renderText={value => <div><h2>Monthly SIP for first child: {value}</h2></div>} />
                  }
                  {(this.state.amount_for_second_child > 0) ?
                    <h2>Your Are Secured</h2>
                    :
                    <CurrencyFormat value={this.state.amount_for_second_child} displayType={'text'} allowNegative={false} thousandSeparator={true} thousandSpacing='2s' prefix={'₹'} renderText={value => <div><h2>Monthly SIP for second child: {value}</h2></div>} />
                  }
                </div>

                <div hidden={this.state.detail_box_for_third_child}>
                  <div className="child_calulator_card">
                    <CardBody>
                      <FormGroup>
                        <Label>Age of Child</Label>
                        <div className="range-slider text-center">
                          <input className="range-slider__range" type="range" min="1" max="18" name="third_child_age" onChange={this.handleChange} value={this.state.fields.third_child_age} id="range_slider" />
                          <input type="message" name="third_child_age" value={this.state.fields.third_child_age} className="range-slider__value" onChange={this.handleChange} /><span> Years</span>
                        </div>
                      </FormGroup>

                      <FormGroup>
                        <Label>Child age at the time of higher study</Label>
                        <div className="range-slider text-center">
                          <input className="range-slider__range" type="range" min="1" max="24" name="third_chid_age_to_start_course" onChange={this.handleChange} value={this.state.fields.third_chid_age_to_start_course} id="range_slider" />
                          <input type="message" name="third_chid_age_to_start_course" value={this.state.fields.third_chid_age_to_start_course} className="range-slider__value" onChange={this.handleChange} /><span> Years</span>
                        </div>
                      </FormGroup>

                      <FormGroup>
                        <Label>Cost of Education as Present Value</Label>
                        <FormControl type="message" name="third_current_price" onChange={this.handleChange} value={this.state.fields.third_current_price} />
                        <div className="errorMsg">{this.state.errors.third_current_price}</div>
                      </FormGroup>

                      <FormGroup>
                        <Label>Education Inflation</Label>
                        <div className="range-slider text-center">
                          <input className="range-slider__range" type="range" min="1" max="25" name="third_rate_of_inflation" onChange={this.handleChange} value={this.state.fields.third_rate_of_inflation} id="range_slider" />
                          <input type="message" name="third_rate_of_inflation" value={this.state.fields.third_rate_of_inflation} className="range-slider__value" onChange={this.handleChange} /><span> %</span>
                        </div>
                      </FormGroup>

                      <FormGroup>
                        <Label>Funds available from existing Savings at the Time of Child’s Higher Education</Label>
                        <FormControl type="message" name="saved_amount_for_third_child" onChange={this.handleChange} value={this.state.fields.saved_amount_for_third_child} />
                        <div className="errorMsg">{this.state.errors.saved_amount_for_third_child}</div>
                      </FormGroup>

                      <FormGroup>
                        <Label>Expected Return</Label>
                        <div className="range-slider text-center">
                          <input className="range-slider__range" type="range" min="1" max="25" name="third_rate_of_return" onChange={this.handleChange} value={this.state.fields.third_rate_of_return} id="range_slider" />
                          <input type="message" name="third_rate_of_return" value={this.state.fields.third_rate_of_return} className="range-slider__value" onChange={this.handleChange} /><span> %</span>
                        </div>
                      </FormGroup>
                    </CardBody>
                  </div>
                  <div hidden={this.state.link_for_4th_detail_box}>
                    <br />
                    <br />
                    <span className='child_span' onClick={this.hideFieldThirdChild.bind(this)}>Add Detail For Another Child</span>
                  </div>
                  <br />
                  <div hidden={this.state.resuls_box_for_third_child}>
                    {(this.state.amount_for_first_child > 0) ?
                    <h2>Your Are Secured</h2>
                    :
                    <CurrencyFormat value={this.state.amount_for_first_child} displayType={'text'} allowNegative={false} thousandSeparator={true} thousandSpacing='2s' prefix={'₹'} renderText={value => <div><h2>Monthly SIP for first child: {value}</h2></div>} />
                    }
                    {(this.state.amount_for_second_child > 0) ?
                    <h2>Your Are Secured</h2>
                    :
                    <CurrencyFormat value={this.state.amount_for_second_child} displayType={'text'} allowNegative={false} thousandSeparator={true} thousandSpacing='2s' prefix={'₹'} renderText={value => <div><h2>Monthly SIP for second child: {value}</h2></div>} />
                    }
                    {(this.state.amount_for_third_child > 0) ?
                      <h2>You Are Secured</h2>
                      :
                      <CurrencyFormat value={this.state.amount_for_third_child} displayType={'text'} allowNegative={false} thousandSeparator={true} thousandSpacing='2s' prefix={'₹'} renderText={value => <div><h2>Monthly SIP for third child: {value}</h2></div>} />
                    }
                  </div>
                </div>
                <div hidden={this.state.detail_box_for_fourth_child}>
                  <div className="child_calulator_card">
                    <CardBody>
                      <FormGroup>
                        <Label>Age of Child</Label>
                        <div className="range-slider text-center">
                          <input className="range-slider__range" type="range" min="1" max="70" name="fourth_child_age" onChange={this.handleChange} value={this.state.fields.fourth_child_age} id="range_slider" />
                          <input type="message" name="fourth_child_age" value={this.state.fields.fourth_child_age} className="range-slider__value" onChange={this.handleChange} /><span> Years</span>
                        </div>
                      </FormGroup>

                      <FormGroup>
                        <Label>Child age at the time of higher study</Label>
                        <div className="range-slider text-center">
                          <input className="range-slider__range" type="range" min="1" max="70" name="fourth_chid_age_to_start_course" onChange={this.handleChange} value={this.state.fields.fourth_chid_age_to_start_course} id="range_slider" />
                          <input type="message" name="fourth_chid_age_to_start_course" value={this.state.fields.fourth_chid_age_to_start_course} className="range-slider__value" onChange={this.handleChange} /><span> Years</span>
                        </div>
                      </FormGroup>

                      <FormGroup>
                        <Label>Cost of Education as Present Value</Label>
                        <FormControl type="message" name="fourth_current_price" onChange={this.handleChange} value={this.state.fields.fourth_current_price} />
                        <div className="errorMsg">{this.state.errors.fourth_current_price}</div>
                      </FormGroup>

                      <FormGroup>
                        <Label>Education Inflation</Label>
                        <div className="range-slider text-center">
                          <input className="range-slider__range" type="range" min="1" max="25" name="fourth_rate_of_inflation" onChange={this.handleChange} value={this.state.fields.fourth_rate_of_inflation} id="range_slider" />
                          <input type="message" name="fourth_rate_of_inflation" value={this.state.fields.fourth_rate_of_inflation} className="range-slider__value" onChange={this.handleChange} /><span> %</span>
                        </div>
                      </FormGroup>

                      <FormGroup>
                        <Label>Funds available from existing Savings at the Time of Child’s Higher Education</Label>
                        <FormControl type="message" name="saved_amount_for_fourth_child" onChange={this.handleChange} value={this.state.fields.saved_amount_for_fourth_child} />
                        <div className="errorMsg">{this.state.errors.saved_amount_for_fourth_child}</div>
                      </FormGroup>

                      <FormGroup>
                        <Label>Expected Return</Label>
                        <div className="range-slider text-center">
                          <input className="range-slider__range" type="range" min="1" max="25" name="fourth_rate_of_return" onChange={this.handleChange} value={this.state.fields.fourth_rate_of_return} id="range_slider" />
                          <input type="message" name="fourth_rate_of_return" value={this.state.fields.fourth_rate_of_return} className="range-slider__value" onChange={this.handleChange} /><span> %</span>
                        </div>
                      </FormGroup>
                    </CardBody>
                  </div>
                  <br/>
                  <div>
                    {(this.state.amount_for_first_child > 0) ?
                    <h2>Your Are Secured</h2>
                    :
                    <CurrencyFormat value={this.state.amount_for_first_child} displayType={'text'} allowNegative={false} thousandSeparator={true} thousandSpacing='2s' prefix={'₹'} renderText={value => <div><h2>Monthly SIP for first child: {value}</h2></div>} />
                    }
                    {(this.state.amount_for_second_child > 0) ?
                    <h2>Your Are Secured</h2>
                    :
                    <CurrencyFormat value={this.state.amount_for_second_child} displayType={'text'} allowNegative={false} thousandSeparator={true} thousandSpacing='2s' prefix={'₹'} renderText={value => <div><h2>Monthly SIP for second child: {value}</h2></div>} />
                    }
                    {(this.state.amount_for_third_child > 0) ?
                      <h2>You Are Secured</h2>
                      :
                      <CurrencyFormat value={this.state.amount_for_third_child} displayType={'text'} allowNegative={false} thousandSeparator={true} thousandSpacing='2s' prefix={'₹'} renderText={value => <div><h2>Monthly SIP for third child: {value}</h2></div>} />
                    }
                    {(this.state.amount_for_fourth_child > 0) ?
                      <h2>You Are Secured</h2>
                      :
                      <CurrencyFormat value={this.state.amount_for_fourth_child} displayType={'text'} allowNegative={false} thousandSeparator={true} thousandSpacing='2s' prefix={'₹'} renderText={value => <div><h2>Monthly SIP for fourth child : {value}</h2></div>} />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    )
  }
}