import React from "react";
import abhishek from "../../Images/abhishek.jpg";
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import { AvForm, AvField } from 'availity-reactstrap-validation'
import { Button } from 'reactstrap';
import ScrollableAnchor from 'react-scrollable-anchor';
import { Textfit } from 'react-textfit';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import $ from 'jquery';
import {toast,ToastContainer} from 'react-toastify';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.min.css'

export default class HomePage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
       fields: {name: '', email:'', subject:'', message:'', phonenumber:''},
        errors: {}
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  
    handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields
    });
  };

  handleSubmit = (e) => {
    var data = {
      contact: {
        name: this.state.fields.name,
        email: this.state.fields.email,
        subject: this.state.fields.subject,
        message: this.state.fields.message,
        mobile_No: this.state.fields.phonenumber
      }
    }
    e.persist() 
      let fields = {};
      fields["name"] = "";
      fields["email"] = "";
      fields["subject"] = "";
      fields["message"] = "";
      fields["phonenumber"]="";
      this.setState({fields:fields});

    axios({
      method: 'post',
      url: (process.env.REACT_APP_API_URL) + '/contacts',
      data: data,
      dataType: 'json'
    })
      .then(function (response) {
        if (response.status === 200) {
        toast.success('We Will Get Back To You Soon', {
          position: "top-right",
          autoClose: 2200,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false
          });
        }
    })
      .catch(function (error) {
      if(error.response.status === 500){
        toast.error('Wrong Credentials', {
          position: "top-right",
          autoClose: 2200,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false
          });
       }
      });
  }

   


  cursormovment = () => {
    $('#user_name').focus();
  }
  
  render() {
    return (

      <div>
        <div>
          <Helmet>
            <meta charSet="utf-8" />
            <title>HomePage</title>
          </Helmet>
        </div>

        <section className="text-center tagline">
          <ToastContainer />
          <div className='text-center'>
            <div className="display-4">We Advise, You Prosper</div>
          </div>
          <p className="lead">Get expert advice and make a great portfolio for your future by investing in right plan</p>
          <p className="lead">
            <a className="btn btn-success btn-lg page-scroll" href="#Services" role="button">Learn more</a>
          </p>
        </section>

        <ScrollableAnchor id={'Services'}>
          <section className="services">
            <div className="container">
              <div className='text-center'>
                <h2 className="display-4">Wealth Management Services</h2>
                <h2 className='text-purple'>~</h2>
              </div>
              <h3 className="text-center text-purple">Grow your assets with WealthUp Capital</h3>
              <br />
              <br />
              <div className="row">
                <div className="col-md-4 col-sm-12">
                  <div className='text-center'>
                    <span className="icon-time-money purple-circle display-3"></span>
                    <h3 className='text-purple'>Mutual Fund</h3>
                  </div>
                  <p className='text-justify'>Get best plan in Equity, Debt or Hybrid according to your requirement and risk profile. It will be customized for you.</p>
                  <a href='/GoalCalculator'>Have a Goal? Calculate your SIP</a><br />
                  <a href='/SipCalculator'>Know what will be the future value of your SIP</a><br />
                  <a href='/RiskProfileCalculator'>Know your Risk Profile</a><br />
                  <br />
                  <a href='https://wealthupcapital.finsuite.in/0/Client-Page/Onboarding' target='_blank' rel="noopener noreferrer" className='pull-left btn btn-success'>Sign In</a>
                  <Link to='/mutualfunds' className='pull-right btn btn-success'>Read More</Link>
                </div>

                <div className="col-md-4 col-sm-12">
                  <div className='text-center'>
                    <span className="icon-graphic purple-circle display-3"></span>
                    <h3 className='text-purple'>PMS</h3>
                  </div>
                  <p className='text-justify'>Premium Investment services for HNI, Ultra-HNI investors with higher return expectation hand higher level of risk tolerance.</p>
                  <a href='/#contact'>Get a call from expert.</a><br />
                  <br />
                  <br />
                  <br />
                  <a href='/pms' className='pull-right btn btn-success'>Read More</a>
                </div>

                <div className="col-md-4 col-sm-12">
                  <div className='text-center'>
                    <span className="icon-insurance purple-circle display-3"></span>
                    <h3><Textfit
                      mode="single"
                      forceSingleModeWidth={false} className='text-purple'>
                      Risk Management
                    </Textfit></h3>
                  </div>
                  <p className='text-justify'>Get best cover for Health Insurance, Life Insurance, Home Insurance, Auto Insurance, Travel Insurance and many more.</p>
                  <a href='/LifeInsuranceForm'>Protect your family with Life Insurance.</a><br />
                  <a href='/HealthInsuranceFrom'>Need a Health Insurance?</a><br />
                  <br />
                  <br />
                  <a href='/insurance' className='pull-right btn btn-success'>Read More</a>
                </div>
              </div>
            </div>
          </section>
        </ScrollableAnchor>

        <ScrollableAnchor id={'about'}>
          <section className="expert">
            <div className="container">
              <h2 className="display-4 text-center">About Expert</h2>
              <br />
              <img className="center1 avatar" src={abhishek} alt="Abhishek Kumar" />
              <br />
              <div className="col-md-6 col-sm-12 offset-md-3">
                <p>WealthUp Capital is specialized in Wealth Management Services. We offer a range of investment solutions viz. Mutual Funds, Life Insurance, Health Insurance, Portfolio Management Services, Bonds & Fixed Deposits. Committed to give advice based on need with latest technological support. Trust, Quality & Technology is hallmark of our Portfolio advisory.</p>
                <p>WealthUp Capital is founded by seasoned investment Professional Mr. Abhishek Kumar who has worked in Aditya Birla Sun Life Asset Management Company & ICICI Bank  for more than 13 years in Corporate Advisory & Wealth Management.</p>

                <h4>What you will get?</h4>
                <p><b>Fiduciary:</b> Work in a client’s best interests all the time.</p>
                <p><b>Relationship:</b> Investment is not one time. You will receive continuous comprehensive financial plan throughout life’s stages and changes</p>
                <p><b>Comprehensiveness:</b> You will receive a roadmap for your financial planning. And then you don’t have to worry about insurance, education, investments, retirement.</p>
                <p><b>Ability to handle big assets.</b></p>
                <p><b>Availability:</b> I am always available to answer your question. If you require appointment from expert, SMS “ INVEST “ on 8510086060</p>
                <span className="glyphicon glyphicon-envelope"></span> <a href='mailto:abhishek@wealthupcapital.com'>abhishek@wealthupcapital.com</a>
                <br />
                <span className="glyphicon glyphicon-phone"></span>  <a href="tel:8510086060">Tel: 851-008-6060 </a>
              </div>
            </div>
          </section>
        </ScrollableAnchor>

        <section className='body2'>
          <div className="container">
            <div className='text-center'>
              <h2 className="display-4 text-center">How we do it?</h2>
              <h2 className='text-purple'>~</h2>
            </div>
            <br />
            <br />
            <div className='row'>
              <div className='col-md-3 col-sm-12'>
                <div className='text-center'>
                  <span className="icon-goal purple-circle display-3"></span>
                  <h3 className='text-purple'><Textfit
                    mode="single"
                    forceSingleModeWidth={false}>
                    Goal Analysis
                    </Textfit></h3>
                </div>
                <p className='text-justify'>We will discuss with you to know what is your goal for future and what you have right now.</p>
              </div>
              <div className='col-md-3 col-sm-12'>
                <div className='text-center'>
                  <span className="icon-search purple-circle display-3"></span>
                  <h3 className='text-purple'><Textfit
                    mode="single"
                    forceSingleModeWidth={false}>
                    Risk Evaluation
                    </Textfit></h3>
                </div>
                <p className='text-justify'>We evaluate how much Risk you can take and how much time and money you have.</p>
              </div>
              <div className='col-md-3 col-sm-12'>
                <div className='text-center'>
                  <span className="icon-idea purple-circle display-3"></span>
                  <h3 className='text-purple'>Design</h3>
                </div>
                <p className='text-justify'>Based on requirement received from you we design your plan to ensure best desired result</p>
              </div>
              <div className='col-md-3 col-sm-12'>
                <div className='text-center'>
                  <span className="icon-rocket purple-circle display-3"></span>
                  <h3 className='text-purple'>Execution</h3>
                </div>
                <p className='text-justify'>We collect all data from you to execute our plan. No matter where in the world you are.</p>
              </div>
            </div>
            <br />
            <br />
          </div>
        </section>

        <div className='container-fluid learn_section body2'>
          <h2 className="display-4 text-center">Learn ...</h2>
          <h2 className='text-center'>~</h2>
          <br />
          <br />
          <div className="row text-center">
            <div className="col-md-4 col-sm-12">
              <span><Link to='/SipCalculator'>Know how much you will save through your SIP</Link></span>
            </div>
            <div className="col-md-4 col-sm-12">
              <span><Link to='/RetirementCalculator'>Plan your Retirement</Link></span>
            </div>
            <div className="col-md-4 col-sm-12">
              <span><Link to='/LifeInsuranceCalculator'>Know how much cover you need</Link></span>
            </div>
          </div>
          <div className="row text-center">
            <div className="col-md-4 col-sm-12">
              <span><Link to='/GoalCalculator'>How much I should invest to achieve my goal</Link></span>
            </div>
            <div className="col-md-4 col-sm-12">
              <span><Link to='/ChildEductionCalculator'>Plan your child’s education</Link></span>
            </div>
            <div className="col-md-4 col-sm-12">
            </div>
          </div>
          <div className="row text-center">
            <div className="col-md-4 col-sm-12">
              <span><Link to='/RiskProfileCalculator'>Evaluate Your Risk Profile</Link></span>
            </div>
            <div className="col-md-4 col-sm-12">
              <span><Link to='/FutureValueCalculator'>Know Future value</Link></span>
            </div>
            <div className="col-md-4 col-sm-12">
              
            </div>
          </div>
        </div>
                
        <ScrollableAnchor id={'contact'}>
          <section className="expert">
            <div className="container contact">
              <h2 className="display-4 text-center contact_color">Contact Us</h2>
              <h2 className='text-center contact_color'>~</h2>
              <br />
              <h4 className='text-center contact_color'>for</h4>
              <div className='center'><Link to='/MutualFundsForm'>Mutual Funds</Link> | <Link to='/LifeInsuranceForm'>Life Insurance</Link> | <Link to='/HealthInsuranceFrom'>Health Insurance</Link> | <span className='child_span other' onClick={this.cursormovment.bind(this)}>Other</span></div>
              <br />
              <br />
              <AvForm onSubmit={this.handleSubmit} data-disabled>
                <Row>
                  <Col sm={6} xs={12}>
                    <AvField type="text" id='user_name' name="name" placeholder="Name *" className="form_font_size" onChange={this.handleChange.bind(this)} value={this.state.fields.name} validate={{
                    pattern: {value: '^[a-zA-Z-,]+([a-zA-Z-, ])*$', errorMessage: 'Your name must be composed only with letter'},
                    minLength: {value: 6, errorMessage: 'Your name must be between 6 and 16 characters'},
                    maxLength: {value: 16, errorMessage: 'Your name must be between 6 and 16 characters'}
                    }} />
                  </Col>
                  <Col sm={6} xs={12}>
                    <AvField type="email" name="email" placeholder="Email Address" className="form_font_size" onChange={this.handleChange} value={this.state.fields.email} validate={{email: true}} />
                  </Col>
                </Row>
                <Row>
                  <Col sm={6} xs={12}>
                     <AvField 
                      name="subject"
                      className="form_font_size home_page_select_box"
                      type="select" 
                      onChange={this.handleChange} 
                      value={this.state.fields.subject}
                      >
                      <option>Subject</option> 
                      <option>Mutual Funds</option>
                      <option>Life Insurance</option>
                      <option>Health Insurance</option>
                      <option>Other</option>
                     </AvField>
                  </Col>
                  <Col sm={6} xs={12}>
                   <AvField type="text" name="phonenumber" placeholder="Mobile No." className="form_font_size" onChange={this.handleChange} value={this.state.fields.phonenumber}  validate={{
                    pattern: {value:'^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$', errorMessage: 'Input Numbers'},
                    }} />
                  </Col>
                </Row>
                   <AvField  className="form_font_size" type="text" name="message" placeholder="Message" style={{ 'height': '130px' }} onChange={this.handleChange} value={this.state.fields.message} />
                <br />
                <br />
                 <Button type="submit" className="pull-right btn btn-success">Send</Button>
              </AvForm>
            </div>
          </section>
        </ScrollableAnchor>
      </div>

    );
  }
}
