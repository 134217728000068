import React from 'react';
import { Card, CardBody,FormGroup, Label} from 'reactstrap';
import FormControl from 'react-bootstrap/lib/FormControl';
import CurrencyFormat from 'react-currency-format';

export default class FutureValueCalculator extends React.Component{
 
constructor() {
    super();
    this.state = {
      fields: {investment_amount: '30000',interest_rate: '11',duration: '12'  },
      errors: {},
      amount: '1,11,629'
    }
    this.handleChange = this.handleChange.bind(this);
  };

handleChange(e) {
  let fields = this.state.fields;
  fields[e.target.name] = e.target.value;
  this.setState(fields,function(){
    if(this.validateForm()){
    this.calculate();
    }
  });
};

  validateForm() {

      let fields = this.state.fields;
      let errors = {};
      let formIsValid = true;
			
      if (!fields["investment_amount"]) {
        formIsValid = false;
        errors["investment_amount"] = "*Please enter investment amount ";
      }

      if (typeof fields["investment_amount"] !== "undefined") {
        if (fields["investment_amount"].match(/^[a-zA-Z ]*$/)) {
          formIsValid = false;
          errors["investment_amount"] = "*Please enter numbers only";
        }
      }
	    
	    if (!fields["interest_rate"]) {
        formIsValid = false;
        errors["interest_rate"] = "*Please enter interest rate";
      }

      if (typeof fields["interest_rate"] !== "undefined") {
        if (fields["interest_rate"].match(/^[a-zA-Z ]*$/)) {
          formIsValid = false;
          errors["interest_rate"] = "*Please enter numbers only";
        }
      }
   
	    if (!fields["duration"]) {
        formIsValid = false;
        errors["duration"] = "*Please enter time duration";
      }

      if (typeof fields["duration"] !== "undefined") {
        if (fields["duration"].match(/^[a-zA-Z ]*$/)) {
          formIsValid = false;
          errors["duration"] = "*Please enter numbers only";
        }
      }
   
      this.setState({
        errors: errors
      });
      return formIsValid;
      }	
  
  
  
calculate = () =>{
   
  var rate_of_interest = this.state.fields.interest_rate / 1200
  var time = 12 * this.state.fields.duration
  var inflation_multiple = (1 + rate_of_interest)
  var power_fuction = Math.pow(inflation_multiple, time)
  var final = this.state.fields.investment_amount * power_fuction 
  this.setState({amount: Math.round(final)})
}  
  
  render(){
    
    return(
           
 <div className='body2'>
 <div className='container'>
  <br/>
   <div className="form_style2">
   <div className="col-sm-12 ">
    <div className='text-center'>
      <h2 className="display-4 heading_font">Future Value Calcultor</h2>
    </div>
    <br/>
    <br/>
    <Card >
     <CardBody>
       <FormGroup>
         <Label>Investment Amount</Label>
         <FormControl type="message" name="investment_amount" onChange={this.handleChange} value={this.state.fields.investment_amount}/>
         <div className="errorMsg">{this.state.errors.investment_amount}</div>
       </FormGroup> 
       
        <FormGroup>
        <Label>Interest Rate</Label>
         <div className="range-slider text-center">
          <input className="range-slider__range" type="range"  min="1" max="25" name="interest_rate" onChange={this.handleChange} value= {this.state.fields.interest_rate} id="range_slider"/>
          <input type="message" name="interest_rate" value={this.state.fields.interest_rate} className="range-slider__value2" onChange={this.handleChange}/><span> %</span>
          <div className="errorMsg">{this.state.errors.interest_rate}</div> 
         </div>
       </FormGroup>
       <FormGroup>
         <Label>Investment Duratiion</Label>
         <div className="range-slider text-center">
          <input className="range-slider__range" type="range"  min="1" max="70" name="duration" onChange={this.handleChange} value={this.state.fields.duration} id="range_slider"/>
          <input type="message" name="duration" value={this.state.fields.duration} className="range-slider__value2" onChange={this.handleChange}/><span> Years</span>
          <div className="errorMsg">{this.state.errors.duration}</div>
         </div>
       </FormGroup>
       <div>
          {(this.state.fields.current_price !== '') ?
          <CurrencyFormat value={this.state.amount} displayType={'text'} allowNegative={false} thousandSeparator={true} thousandSpacing='2s' prefix={'₹'} renderText={value => <div><h2>Your future value is : {value}</h2></div>}/>
          : 
          <h2>Amount you should save monthly: ₹</h2>
          }
       </div>
     </CardBody>
    </Card>  
    <br/>
    <br/>
    </div>
   </div>
  </div>
 </div>         
    
    )
  }
}