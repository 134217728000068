import React from 'react';
import { Switch, Route } from 'react-router-dom';
import HomePage from './component/HomePage/HomePage.jsx';
import MutualFunds from './component/MutualFunds/MutualFunds.jsx';
import PMS from './component/PMS/PMS.jsx';
import RiskManagment from './component/RiskManagment/RiskManagment.jsx';
import ComingSoon from './component/ComingSoon/ComingSoon.jsx';
import LifeInsuranceCalculator from './component/LifeInsuranceCalculator/LifeInsuranceCalculator.jsx';
import GoalCalculator from './component/GoalCalculator/GoalCalculator.jsx';
import SipCalculator from './component/SipCalculator/SipCalculator.jsx';
import HealthInsuranceFrom from './component/HealthInsuranceFrom/HealthInsuranceFrom.jsx';
import LifeInsuranceForm from './component/LifeInsuranceForm/LifeInsuranceForm.jsx';
import MutualFundsForm from './component/MutualFundsForm/MutualFundsForm.jsx';
import RetirementCalculator from './component/RetirementCalculator/RetirementCalculator.jsx';
import RiskProfileCalculator from './component/RiskProfileCalculator/RiskProfileCalculator.jsx';
import ChildEductionCalculator from './component/ChildEductionCalculator/ChildEductionCalculator.jsx';
import FutureValueCalculator from './component/FutureValueCalculator/FutureValueCalculator.jsx';
const Main = () => (

	<main>
		<Switch >
			<Route exact path='/' component={HomePage} />
			<Route exact path="/mutualfunds" component={MutualFunds} />
			<Route exact path="/pms" component={PMS} />
			<Route exact path="/insurance" component={RiskManagment} />
			<Route exact path="/comingsoon" component={ComingSoon} />
			<Route exact path="/LifeInsuranceCalculator" component={LifeInsuranceCalculator} />
			<Route exact path="/GoalCalculator" component={GoalCalculator} />
			<Route exact path="/SipCalculator" component={SipCalculator} />
			<Route exact path="/HealthInsuranceFrom" component={HealthInsuranceFrom} />
			<Route exact path="/LifeInsuranceForm" component={LifeInsuranceForm} />
			<Route exact path="/MutualFundsForm" component={MutualFundsForm} />
			<Route exact path="/RetirementCalculator" component={RetirementCalculator} />
			<Route exact path="/RiskProfileCalculator" component={RiskProfileCalculator} />
			<Route exact path="/ChildEductionCalculator" component={ChildEductionCalculator} />
			<Route exact path="/FutureValueCalculator" component={FutureValueCalculator} />

		</Switch>
	</main>

);


export default Main;