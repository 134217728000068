import React from 'react';
import Main from './routes.js';
import './css/bootstrap.css';
import './css/fonts.css';
import './css/icomoon.css';
import './css/custom.css';
import 'jquery/dist/jquery.min.js';
import 'popper.js/dist/umd/popper';
import 'bootstrap/dist/js/bootstrap.min.js';

import Header from './component/Header/Header.jsx';
import Footer from './component/Footer/Footer.jsx';

export default class App extends React.Component{
	
	render(){
		return(
		  <div>
			  <Header />
			    <Main />
			  <Footer />
			</div>
		);
	}
}