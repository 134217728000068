import React from 'react';
import { Card, CardBody, Form, FormGroup, Label, CustomInput } from 'reactstrap';
import $ from 'jquery';
import CurrencyFormat from 'react-currency-format';
import './life.css';



export default class LifeInsuranceCalculator extends React.Component {

	constructor() {
		super();
		this.state = {
			fields: { depend: 'Yes', annualamount: '5', yeartoreplace: '20', debt: '0', childreneducation: '0', lifeinsurance: '0', aftertaxincome: '0', debt2: 'No', education: 'No', insurance: 'No', taxincome: 'No' },
			errors: {},
			label: 'Do you have any debt?', label2: 'Do you want to save money for your child education?', label3: 'Do you have Life Insurance?', label4: 'Do you have any another source of income?(e.g-Rent)', hide: true, hide2: true, hide3: true, hide4: true, amount: '1000000'
		}
		this.handleChange = this.handleChange.bind(this);
	};

	handleChange = (e) => {
		let fields = this.state.fields;
		fields[e.target.name] = e.target.value;
		this.setState(fields, function () {
			this.calculate();
		}.bind(this));
	}

	debtChange = (e) => {
		let fields = this.state.fields;
		fields['debt2'] = e.target.value;

		this.setState({ fields })
		if (e.target.value === "Yes") {
			this.setState({ label: "How much debt do you have?", hide: false })
		}
		else {
			this.setState({ label: "Do you have any debt?", hide: true }, function () {
				fields['debt'] = 0;
				this.setState(fields, function () {
					this.calculate();
				})
			})
		}
	}

	childreneducationChange = (e) => {
		let fields = this.state.fields;
		fields['education'] = e.target.value;

		this.setState({ fields })
		if (e.target.value === "Yes") {
			this.setState({ label2: "How much money do you want for child education?", hide2: false })
		}
		else {
			this.setState({ label2: "Do you want to provide money for your child education?", hide2: true }, function () {
				fields['childreneducation'] = 0;
				this.setState(fields, function () {
					this.calculate();
				})
			})
		}
	}

	lifeinsuranceChange = (e) => {
		let fields = this.state.fields;
		fields['insurance'] = e.target.value;
		this.setState({ fields })
		if (e.target.value === "Yes") {
			this.setState({ label3: "How much life insurance do you already have?", hide3: false })
		}
		else {
			this.setState({ label3: "Do you have Life Insurance?", hide3: true }, function () {
				fields['lifeinsurance'] = 0;
				this.setState(fields, function () {
					this.calculate();
				})
			})
		}
	}

	aftertaxincomeChange = (e) => {
		let fields = this.state.fields;
		fields['taxincome'] = e.target.value;

		this.setState({ fields })
		if (e.target.value === "Yes") {
			this.setState({ label4: "How much Annula Income from aother source of income?(e.g-Rent)?", hide4: false })
		}
		else {
			this.setState({ label4: "Do you have any aother source of income?(e.g-Rent)?", hide4: true }, function () {
				fields['aftertaxincome'] = 0;
				this.setState(fields, function () {
					this.calculate();
				})
			})
		}
	}

	handleannualamountChange = (e) => {
		let fields = this.state.fields;
		fields['annualamount'] = e.target.value;
		this.setState(fields, function () {
			this.calculate();
		}.bind(this));
	}

	handleyeartoreplaceChange = (e) => {
		let fields = this.state.fields;
		fields['yeartoreplace'] = e.target.value;
		this.setState(fields, function () {
			this.calculate();
		}.bind(this));
	}

	handledebtChange = (e) => {
		let fields = this.state.fields;
		fields['debt'] = e.target.value;
		this.setState(fields, function () {
			this.calculate();
		}.bind(this));
	}

	handlechildreneducationChange = (e) => {
		let fields = this.state.fields;
		fields['childreneducation'] = e.target.value;
		this.setState(fields, function () {
			this.calculate();
		}.bind(this));
	}

	handlelifeinsuranceChange = (e) => {
		let fields = this.state.fields;
		fields['lifeinsurance'] = e.target.value;
		this.setState(fields, function () {
			this.calculate();
		}.bind(this));
	}

	handleaftertaxincomeChange = (e) => {
		let fields = this.state.fields;
		fields['aftertaxincome'] = e.target.value;
		this.setState(fields, function () {
			this.calculate();
		}.bind(this));
	}

	calculate = () => {

		var step1 = this.state.fields.annualamount * this.state.fields.yeartoreplace * 100000
		console.log(step1)
		var step2 = this.state.fields.aftertaxincome * this.state.fields.yeartoreplace * 100000
		console.log(step2)
		var step3 = (step1 - step2)
		var step4 = (this.state.fields.debt * 100000) + (this.state.fields.childreneducation * 100000)
		var step5 = step4 - (this.state.fields.lifeinsurance * 100000)
		var final = (step5 + step3)
		this.setState({ amount: Math.round(final) })
	}

	componentDidMount() {

		var rangeSlider = function () {
			var slider = $('.range-slider'),
				range = $('.range-slider__range'),
				value = $('.range-slider__value');

			slider.each(function () {

				value.each(function () {
					var value = $(this).prev().attr('value');
					$(this).html(value);
				});

				range.on('input', function () {
					$(this).next(value).html(this.value);
				});
			});
		};

		rangeSlider();

	}

	render() {

		return (

			<div className="body2">
				<div className="container">
					<br />
					<div id="calculator" className='form_style2'>
						<div className='col-sm-12'>
							<h2 className='text-center'>How Much Insurance Do You Need</h2>
							<Card>
								<CardBody>
									<Form>
										<FormGroup>
											<Label for="exampleEmail">How much annual income would you need to replace if something were to happen to you?</Label>
											<div className="range-slider text-center">
												<input className="range-slider__range" type="range" min="5" max="100" name="annualamount" onChange={this.handleChange} value={this.state.fields.annualamount} />
												<input type="message" onChange={this.handleannualamountChange.bind(this)} value={this.state.fields.annualamount} className="range-slider__value2" /><span> Lakhs</span>
											</div>
										</FormGroup>

										<FormGroup>
											<Label for="exampleEmail">How many years would your family need to replace your income and the services you provide if you died suddenly?</Label>
											<div className="range-slider text-center">
												<input className="range-slider__range" type="range" min="18" max="70" name="yeartoreplace" onChange={this.handleChange} value={this.state.fields.yeartoreplace} />
												<input type="message" onChange={this.handleyeartoreplaceChange.bind(this)} value={this.state.fields.yeartoreplace} className="range-slider__value2" /><span> Years</span>
											</div>
										</FormGroup>

										<FormGroup>
											<Label for="exampleEmail">{this.state.label}</Label><span>  </span>
											<CustomInput type="radio" id="exampleCustomRadio2" name="debt2" label="Yes" value="Yes"
												checked={this.state.fields.debt2 === 'Yes'}
												onChange={this.debtChange.bind(this)} inline />
											<CustomInput type="radio" id="exampleCustomRadio3" name="debt2" label="No" value="No"
												checked={this.state.fields.debt2 === 'No'}
												onChange={this.debtChange.bind(this)} inline />
											<div className="range-slider text-center" hidden={this.state.hide}>
												<input className="range-slider__range" type="range" min="1" max="100" name="debt" onChange={this.handleChange} value={this.state.fields.debt} />
												<input type="message" onChange={this.handledebtChange.bind(this)} value={this.state.fields.debt} className="range-slider__value2" /> <span> Lakhs</span>
											</div>
										</FormGroup>

										<FormGroup>
											<Label for="exampleEmail">{this.state.label2}</Label><span>  </span>
											<CustomInput type="radio" id="exampleCustomRadio6" name="education" label="Yes" value="Yes"
												checked={this.state.fields.education === 'Yes'}
												onChange={this.childreneducationChange.bind(this)} inline />
											<CustomInput type="radio" id="exampleCustomRadi07" name="education" label="No" value="No"
												checked={this.state.fields.education === 'No'}
												onChange={this.childreneducationChange.bind(this)} inline />
											<div className="range-slider text-center" hidden={this.state.hide2}>
												<input className="range-slider__range" type="range" min="5" max="100" name="childreneducation" onChange={this.handleChange} value={this.state.fields.childreneducation} />
												<input type="message" onChange={this.handlechildreneducationChange.bind(this)} value={this.state.fields.childreneducation} className="range-slider__value2" /><span> Lakhs</span>
											</div>
										</FormGroup>

										<FormGroup>
											<Label for="exampleEmail">{this.state.label3}</Label><span>  </span>
											<CustomInput type="radio" id="exampleCustomRadio8" name="insurance" label="Yes" value="Yes"
												checked={this.state.fields.insurance === 'Yes'}
												onChange={this.lifeinsuranceChange.bind(this)} inline />
											<CustomInput type="radio" id="exampleCustomRadio9" name="insurance" label="No" value="No"
												checked={this.state.fields.insurance === 'No'}
												onChange={this.lifeinsuranceChange.bind(this)} inline />
											<div className="range-slider text-center" hidden={this.state.hide3}>
												<input className="range-slider__range" type="range" min="5" max="500" name="lifeinsurance" onChange={this.handleChange} value={this.state.fields.lifeinsurance} />
												<input type="message" onChange={this.handlelifeinsuranceChange.bind(this)} value={this.state.fields.lifeinsurance} className="range-slider__value2" /><span> Lakhs</span>
											</div>
										</FormGroup>

										<FormGroup>
											<Label for="exampleEmail">{this.state.label4}</Label><span>  </span>
											<CustomInput type="radio" id="exampleCustomRadio4" name="taxincome" label="Yes" value="Yes"
												checked={this.state.fields.taxincome === 'Yes'}
												onChange={this.aftertaxincomeChange.bind(this)} inline />
											<CustomInput type="radio" id="exampleCustomRadio5" name="taxincome" label="No" value="No"
												checked={this.state.fields.taxincome === 'No'}
												onChange={this.aftertaxincomeChange.bind(this)} inline />
											<div className="range-slider text-center" hidden={this.state.hide4}>
												<input className="range-slider__range" type="range" min="1" max="100" name="aftertaxincome" onChange={this.handleChange} value={this.state.fields.aftertaxincome} />
												<input type="message" onChange={this.handleaftertaxincomeChange.bind(this)} value={this.state.fields.aftertaxincome} className="range-slider__value2" /><span> Lakhs</span>
											</div>
										</FormGroup>
										{(this.state.amount < 0) ?
											<h2>You Are Adequately Insured</h2>
											:
											<CurrencyFormat value={this.state.amount} displayType={'text'} allowNegative={false} thousandSeparator={true} thousandSpacing='2s' prefix={'₹'} renderText={value => <div><h2>Insurance Amount : {value}</h2></div>} />
										}
									</Form>
								</CardBody>
							</Card>
						</div>
						<div>
						</div>
						<br />
					</div>
				</div>
			</div>
		);
	}
}