import React from 'react';
import insurance from '../../Images/insurance.png';
import auto_insurance from '../../Images/auto_insurance.png';
import commercial_insurance from '../../Images/commercial_insurance.png';
import healthinsurance from '../../Images/healthinsurance.png';
import lifeinsurance from '../../Images/lifeinsurance.png';
import home_insurance from '../../Images/home_insurance.png';
import travel_insurance from '../../Images/travel_insurance.png';
import { Helmet } from "react-helmet";

import { Link } from 'react-router-dom';


export default class RiskManagment extends React.Component {

	render() {

		return (

			<div className='body2'>
				<Helmet>
					<meta charSet="utf-8" />
					<title>RiskManagment</title>
				</Helmet>

				<div className="container2 text-center">
					<br />
					<br />
					<div className="display-4">Risk Management</div>
					<br />
					<p className="lead">
						<a className="btn btn-success btn-lg page-scroll" href="/#contact" role="button">Get a quote</a>
					</p>
					<img src={insurance} alt="insurance" style={{ 'width': '100%' }} />
					<br />
					<br />
				</div>
				<section></section>
				<div className="container">
					<h2 className="display-4 text-center">Get Covered with...</h2>

					<section>
						<div className="row">
							<div className="col-md-6 col-sm-12">
								<div className='text-center'>
									<div className='risk_images'>
										<img src={lifeinsurance} alt='lifeinsurance' />
									</div>
									<br />
									<br />
									<h3 className='text-purple'>Life Insurance</h3>
								</div>
								<br />
								<p className='text-justify'>So that your loss doesn’t become financial loss for your family. secure future of your family with Life Insurance.</p>
								<br />
								<br />
								<Link to="/LifeInsuranceCalculator">Know how much cover you need</Link>
								<br />
								<Link to="/LifeInsuranceForm">Get a quote </Link>
							</div>
							<div className="col-md-6 col-sm-12">
								<div className='text-center'>
									<div className='risk_images'>
										<img src={healthinsurance} alt='healthinsurance' style={{ 'marginTop': '66px' }} />
									</div>
									<br />
									<br />
									<h3 className='text-purple'>Health Insurance</h3>
								</div>
								<br />
								<p className='text-justify'>In the time of sky-high medical expenses get financial stability with health insurance for you and your family. It can include medical costs, hospitalisation costs, medication and laboratory test costs and critical illness</p>
								<br />
								<Link to="/HealthInsuranceFrom">Get a quote </Link>
							</div>
						</div>
					</section>
					<section>
						<div className="row">
							<div className="col-md-6 col-sm-12">
								<div className='text-center'>
									<div className='risk_images'>
										<img src={home_insurance} alt='home_insurance' style={{ 'marginTop': '66px' }} />
									</div>
									<br />
									<br />
									<h3 className='text-purple'>Home Insurance</h3>
								</div>
								<br />
								<p className='text-justify'>Protect rebuilding your costliest asset. Get a cover for your home and household items including jewelries, valuables, works of art from unseen unexpected circumstances such as fire, earthquake and other natural calamities, burglary and  theft.</p>
								<br />
								<Link to="/#contact">Insure your home or content or both</Link>
							</div>
							<div className="col-md-6 col-sm-12">
								<div className='text-center'>
									<div className='risk_images'>
										<img src={auto_insurance} alt='auto_insurance' style={{ 'marginTop': '66px' }} />
									</div>
									<br />
									<br />
									<h3 className='text-purple'>Auto Insurance</h3>
								</div>
								<br />
								<p className='text-justify'>Car insurance is compulsory by law in India. Get a cover in case of natural calamities, man-made calamities and Personal Accident.</p>
								<br />
								<br />
								<br />
								<Link to="/#contact">Get a quote </Link>
							</div>
						</div>
					</section>
					<section>
						<div className="row">
							<div className="col-md-6 col-sm-12">
								<div className='text-center'>
									<div className='risk_images'>
										<img src={travel_insurance} alt='travel_insurance' />
									</div>
									<br />
									<br />
									<h3 className='text-purple'>Travel Insurance</h3>
								</div>
								<br />
								<p className='text-justify'>Your best friend at unknown place. Enjoy your tour with insurance which cover hospitalization, loss of baggage, trip cancellation, trip curtailment, burglary of your home and other incidental expenses</p>
								<br />
								<Link to="/#contact">Get a quote </Link>
							</div>
							<div className="col-md-6 col-sm-12">
								<div className='text-center'>
									<div className='risk_images'>
										<img src={commercial_insurance} alt='commercial_insurance' style={{ 'marginTop': '66px' }} />
									</div>
									<br />
									<br />

									<h3 className='text-purple'>Commercial Insurance</h3>
								</div>
								<br />
								<p className='text-justify'>Whether you are small enterprise or big, you need commercial insurance for your hard time.</p>
								<br />
								<br />
								<Link to="/#contact">Get a quote </Link>
							</div>
						</div>
					</section>
				</div>

				<div className='container-fluid body2 learn_section'>
					<h2 className="display-4 text-center">Learn ...</h2>
					<h2 className='text-center'>~</h2>
					<br />
					<br />
					<div className="row text-center">
						<div className="col-md-4 col-sm-12">
							<span><Link to='/SipCalculator'>Know how much you will save through your SIP</Link></span>
						</div>
						<div className="col-md-4 col-sm-12">
							<span><Link to='/RetirementCalculator'>Plan your Retirement</Link></span>
						</div>
						<div className="col-md-4 col-sm-12">
							<span><Link to='/LifeInsuranceCalculator'>Know how much cover you need</Link></span>
						</div>
					</div>
					<div className="row text-center">
						<div className="col-md-4 col-sm-12">
							<span><Link to='/GoalCalculator'>How much I should invest to achieve my goal</Link></span>
						</div>
						<div className="col-md-4 col-sm-12">
							<span><Link to='/ChildEductionCalculator'>Plan your child’s education</Link></span>
						</div>
						<div className="col-md-4 col-sm-12">
						</div>
					</div>
					<div className="row text-center">
						<div className="col-md-4 col-sm-12">
							<span><Link to='/RiskProfileCalculator'>Evaluate Your Risk Profile</Link></span>
						</div>
						<div className="col-md-4 col-sm-12">
							<span><Link to='/FutureValueCalculator'>Know Future value</Link></span>
						</div>
						<div className="col-md-4 col-sm-12">
						</div>
					</div>
				</div>
			</div >
		)
	}
}

