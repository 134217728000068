import React from "react";


export default class Footer extends React.Component{

	render(){
		return(

			<div>
				<footer className="page-footer font-small">
					<div className="container text-center text-md-left">
							<div className="row">
									<div className="col-md-4 text-center">
											<h3 className="mb-4 mt-3 text_col">Email & Phone:</h3>
										<p><span className="text_col glyphicon glyphicon-envelope"></span>  <a className="text_col" href='mailto:abhishek@wealthupcapital.com'>abhishek@wealthupcapital.com</a></p>
										<p><span className="text_col glyphicon glyphicon-phone"></span>  <a className="text_col" href="tel:8510086060">Tel: 851-008-6060 </a></p>
									</div>
									 <hr className="clearfix w-100 d-md-none"/>
									<div className="col-md-4 text-center text_col">
										<br/>
										 <ul className="list-unstyled list-inline">
												<li className="list-inline-item">
													<a href='https://www.linkedin.com/in/abhishekkmr' className="btn-floating btn-sm btn-fb mx-1">
														<i className="fa fa-linkedin icon"></i>
													</a>
												</li>
												<li className="list-inline-item">
													<a href='https://twitter.com/@WealthUpCAPITAL' className="btn-floating btn-sm btn-tw mx-1">
														<i className="fa fa-twitter icon" ></i>
													</a>
												</li>
										</ul>
                  </div>
									 <hr className="clearfix w-100 d-md-none"/>
									<div className="col-md-4 text-center">
										<h3 className="mb-4 mt-3  text_col">Address</h3>
										 <p className="text_col">Plot No 110, 2nd Floor, Sector 12, Block-A, Dwarka, New Delhi 110078
										 </p>
									</div>
							</div>
					</div>
				</footer>
			</div>

		);
	}
}


