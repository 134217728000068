import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import "babel-polyfill";
import { BrowserRouter } from 'react-router-dom';
import App from './App.js';
import Analytics from 'react-router-ga';
import ScrollMemory from 'react-router-scroll-memory';


ReactDOM.render((
  <BrowserRouter>
    <Analytics id="UA-123282211" debug>
     <div>
      <ScrollMemory />
      <App />
     </div>
    </Analytics>
  </BrowserRouter>
), document.getElementById('root')
);


