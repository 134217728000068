import React from "react";
import logo from "../../Images/WealthUpCapitalT.png";
import { Link } from 'react-router-dom';

var img_style = {
  display: 'inline-block'
}


export default class Header extends React.Component {

  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-light white-bg sticky-top nav_index">
        <a className="navbar-brand" href='/'>
          <img src={logo} height='50px' style={img_style} alt="logo" />
        </a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
          </ul>
          <form className="form-inline my-2 my-lg-0">
            <li className="nav-link active">
              <Link className='anchor_tag' to='/'>Home</Link>
            </li>
            <li className='nav-link'>
              <Link className='anchor_tag' to='/mutualfunds'>Mutual Funds</Link>
            </li>
            <li className='nav-link'>
              <Link className='anchor_tag' to='/pms'>PMS</Link>
            </li>
            <li className='nav-link'>
              <Link className='anchor_tag' to='/insurance'>Insurance</Link>
            </li>
            <li className='nav-link'>
              <a className='anchor_tag' href="https://wealthupcapital.my-portfolio.in" target='_blank' rel="noopener noreferrer" role="button">Login</a>
            </li>
          </form>
        </div>
      </nav>

    );
  }
}

