import React from 'react';
import mutualfund from '../../Images/mutualfund.png';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";


export default class MutualFunds extends React.Component {

	render() {
		return (

			<div className='body2'>
				<Helmet>
					<meta charSet="utf-8" />
					<title>MutualFunds</title>
				</Helmet>
				<div className="container2 text-center">
					<br />
					<br />
					<div className="display-4">Mutual Funds</div>
					<br />
					<p className="lead">
						<a className="btn btn-success btn-lg page-scroll" href="/#contact" role="button">Contact Expert to Invest</a>
						<br />
						<br />
						<a className="btn btn-success btn-lg page-scroll" href="https://wealthupcapital.finsuite.in/0/Client-Page/Onboarding" target='_blank' rel="noopener noreferrer" role="button">Sign In</a>
					</p>
					<img src={mutualfund} alt="mutualfund" style={{ 'width': '100%' }} />
					<br />
					<br />
				</div>

				<section className='blue-bg'>
					<div className='container'>
						<h2 className="display-4 text-center">Best For ...</h2>
						<h2 className='text-center'>~</h2>
						<br />
						<br />
						<div className="row text-center">
							<div className="col-md-4 col-sm-12">
								<span className="icon-success white-circle display-3"></span><br />
								Short Term / Long Term Financial Goal
									<br />
								<br />
							</div>
							<div className="col-md-4 col-sm-12">
								<span className="icon-accounting white-circle display-3"></span><br />
								Tax Saving
									<br />
								<br />
							</div>
							<div className="col-md-4 col-sm-12">
								<span className="icon-piggy-bank white-circle display-3"></span><br />
								Saving Habit
									<br />
								<br />
							</div>
						</div>
						<div className="row text-center">
							<div className="col-md-4 col-sm-12">
								<span className="icon-parachute white-circle display-3"></span><br />
								Low / Medium / High Risk
									<br />
								<br />
							</div>
							<div className="col-md-4 col-sm-12">
								<span className="icon-network white-circle display-3"></span><br />
								Benefit of Professional Management for Ordinary Investors
									<br />
								<br />
							</div>
							<div className="col-md-4 col-sm-12">
								<span className="icon-analysis white-circle display-3"></span><br />
								Transparency of Portfolio
									<br />
								<br />
							</div>
						</div>
						<div className="row text-center">
							<div className="col-md-4 col-sm-12">
								<span className="icon-diversity white-circle display-3"></span><br />
								Diversified Investment
									<br />
								<br />
							</div>
							<div className="col-md-4 col-sm-12">
								<span className="icon-liquidity white-circle display-3"></span><br />
								Liquidity<br />
								<br />
							</div>
							<div className="col-md-4 col-sm-12">
								<span className="icon-wallet white-circle display-3"></span><br />
								Investment Plans for Everyone
									<br />
								<br />
							</div>
						</div>
					</div>
				</section>

				<section>
					<div className='container'>
						<h2 className="display-4 text-center">All About Asset Classes</h2>
						<h2 className='text-center'>~</h2>
						<br />
						<br />
						<div className="row">
							<div className="col-md-4 col-sm-12">
								<div className='text-center'>
									<h3 className='text-purple'>Equity</h3>
								</div>
								<ul>
									<li>Invested in equity stocks / shares of companies</li>
									<li>High risk, high return</li>
									<li>Tax saving</li>
									<li>Expected return about 12%-15%</li>
									<li>Suitable for Long term Investments – 7 Years +</li>
									<li>Different ranges of funds available - Large Cap, Mid Cap, Small Cap, Multi Cap, Thematic, Value Based investments</li>
								</ul>
							</div>

							<div className="col-md-4 col-sm-12">
								<div className='text-center'>
									<h3 className='text-purple'>Debt</h3>
								</div>
								<ul>
									<li>Invest in fixed income instruments such as Money Market, Govt. & Corporate Bonds</li>
									<li>Low to moderate level of risk, stable return & relatively high liquidity</li>
									<li>Return about 7%-10%</li>
									<li>Ideal for risk-averse investors or short term investment</li>
									<li>More tax efficient than bank FD</li>
								</ul>
							</div>

							<div className="col-md-4 col-sm-12">
								<div className='text-center'>
									<h3 className='text-purple'>Hybrid</h3>
								</div>
								<ul>

									<li>Mix of Asset Classes. Equity, Debt, Gold</li>
									<li>Moderate risk, moderate return</li>
									<li>Return about 10%-12%</li>
									<li>Various schemes with different Equity Debt structure is available</li>
									<li>Equity / Debt taxation plans available</li>
								</ul>
							</div>
						</div>
					</div>
				</section>

				<section className='blue-bg'>
					<div className='container'>
						<h2 className="display-4 text-center">Save Tax Earn More</h2>
						<h2 className='text-center'>~</h2>
						<br />
						<br />
						<div className="row">
							<ul>
								<li>1.50 Lakhs Deduction under 80C</li>
								<li>3 years of Locking</li>
								<li>Minimum Locking amongst available Tax Saving options</li>
								<li>Equity Linked performance</li>
								<li>Investment can be done through lump sum or SIP</li>
							</ul>
						</div>
					</div>
				</section>

				<section>
					<div className='container'>
						<h2 className="display-4 text-center">Learn ...</h2>
						<h2 className='text-center'>~</h2>
						<br />
						<br />
						<div className="row text-center">
							<div className="col-md-4 col-sm-12">
								<span><Link to='/SipCalculator'>Know how much you will save through your SIP</Link></span>
							</div>
							<div className="col-md-4 col-sm-12">
								<span><Link to='/RetirementCalculator'>Plan your Retirement</Link></span>
							</div>
							<div className="col-md-4 col-sm-12">
                <span><Link to='/LifeInsuranceCalculator'>Know how much cover you need</Link></span>
							</div>
						</div>
						<div className="row text-center">
							<div className="col-md-4 col-sm-12">
								<span><Link to='/GoalCalculator'>How much I should invest to achieve my goal</Link></span>
							</div>
							<div className="col-md-4 col-sm-12">
								<span><Link to='/ChildEductionCalculator'>Plan your child’s education</Link></span>
							</div>
							<div className="col-md-4 col-sm-12">
							</div>
						</div>
						<div className="row text-center">
							<div className="col-md-4 col-sm-12">
								<span><Link to='/RiskProfileCalculator'>Evaluate Your Risk Profile</Link></span>
							</div>
							<div className="col-md-4 col-sm-12">
								<span><Link to='/FutureValueCalculator'>Know Future value</Link></span>
							</div>
							<div className="col-md-4 col-sm-12">
								
							</div>
						</div>
					</div>
				</section>
			</div>

		);
	}
}

