import React from 'react';
import {Button,FormGroup, Label, Input, CustomInput} from 'reactstrap';
import './Health.css';
import $ from 'jquery';
import axios from 'axios';
import NotificationSystem from 'react-notification-system';
import InfiniteSections from 'react-infinite-sections';

var style = {
  NotificationItem: {
    DefaultStyle: {
      fontSize: '20px'
    }
  }
}

export default class HealthInsuranceForm extends React.Component {

  _notificationSystem: null

  _addNotification(message) {
    this._notificationSystem.addNotification({
      message: 'We Will Get Back To You Soon',
      level: 'success'
    });
  }

  constructor() {
    super();
    this.state = {
      fields: { age: '25', finalAmount: '5', coveramount: '5', username: '', plan: 'Individual', smoke: 'No', gender: 'Male', emailid: '', mobileno: '', comment: '', subject: 'Health Insurance' },
      errors: {},
      title: "Age", hide: false
    }
    this.handleChange = this.handleChange.bind(this);
    this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
  };

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields
    });
  };

  changeTitleAndHideFields = (e) => {
    let fields = this.state.fields;
    fields['plan'] = e.target.value;
    this.setState({ fields })
    if (e.target.value === "Family") {
      this.setState({ title: "Age of eldest person in family", hide: true })
    }
    else {
      this.setState({ title: "Age", hide: false })
    }
  }

  handleAgeChange(e) {
    let fields = this.state.fields;
    fields['age'] = e.target.value;
    this.setState({
      fields
    });
  };

  handleAnnualAmountChange(e) {
    let fields = this.state.fields;
    fields['finalAmount'] = e.target.value;
    this.setState({
      fields
    });
  };

  handleCoverAmountChange(e) {
    let fields = this.state.fields;
    fields['coveramount'] = e.target.value;
    this.setState({
      fields
    });
  };


  submituserRegistrationForm(e) {
    var data = {
      contact: {
        cover_amount: this.state.fields.coveramount,
        name: this.state.fields.username,
        email: this.state.fields.emailid,
        mobile_No: this.state.fields.mobileno,
        gender: this.state.fields.gender,
        age: this.state.fields.age,
        do_you_smoke: this.state.fields.smoke,
        final_amount: this.state.fields.finalAmount,
        plan: this.state.fields.plan,
        message: this.state.fields.comment,
        subject: this.state.fields.subject
      }
    }
    e.preventDefault();
    let fields = {};
    fields["username"] = "";
    fields["coveramount"] = "5";
    fields["emailid"] = "";
    fields["mobileno"] = "";
    fields["comment"] = "";
    fields["age"] = "25";
    fields["finalAmount"] = "5";
    this.setState({ fields: fields });

    axios({
      method: 'post',
      url: (process.env.REACT_APP_API_URL) + '/contacts',
      data: data,
      dataType: 'json'
    })
      .then(function (response) {
        if (response.status === 200) {
          this._addNotification(response.message);
        }
      }.bind(this))
      .catch(function (error) {
        console.log(error);
      });
}

componentDidMount(){

  this._notificationSystem = this.refs.notificationSystem;

  var rangeSlider = function () {
    var slider = $('.range-slider'),
      range = $('.range-slider__range'),
      value = $('.range-slider__value');

    slider.each(function () {

      value.each(function () {
        var value = $(this).prev().attr('value');
        $(this).html(value);
      });

      range.on('input', function () {
        $(this).next(value).html(this.value);
      });
    });
  };

  rangeSlider();

}

isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key))
      return false;
  }
  return true;
}

render() {
  return (
    <div className="body2">
      <div className="container">
        <br />
        <div id="main-registration-container">
          <div id="register">
            <div className='col-sm-12 col-xs-12 col-md-12'>
              <h2 className='text-center display-4 heading_font'>Enquire for Health Insurance</h2>
              <br />
              <br />
              <InfiniteSections
                className="settings-wrapper"
                animate={true}
                root={{ section: 'root', id: 'incomeDetails' }}
                sections={dispatch => {
                  return {
                    root: [
                      {
                        id: 'incomeDetails',
                        component: (
                          <div>
                            <div className='form-body2'>
                              <div className="header">
                                <h3 className='text-center'>Income Details</h3>
                                <FormGroup>
                                  <Label for="exampleCheckbox">Cover Amount</Label>
                                  <div className="range-slider text-center">
                                    <input className="range-slider__range" type="range" min="5" max="100" name="coveramount" onChange={this.handleChange} value={this.state.fields.coveramount} />
                                    <input type="message" onChange={this.handleCoverAmountChange.bind(this)} value={this.state.fields.coveramount} className="range-slider__value" /><span> Lakhs</span>
                                  </div>
                                </FormGroup>
                                <FormGroup>
                                  <Label for="exampleCheckbox">Annual Earning</Label>
                                  <div className="range-slider text-center">
                                    <input className="range-slider__range" type="range" min="5" max="100" name="finalAmount" onChange={this.handleChange} value={this.state.fields.finalAmount} />
                                    <input type="message" onChange={this.handleAnnualAmountChange.bind(this)} value={this.state.fields.finalAmount} className="range-slider__value" /><span> Lakhs</span>
                                  </div>
                                </FormGroup>
                              </div>
                            </div>
                            <div align="right">
                              {this.state.fields.coveramount.match(/^[a-zA-Z ]*$/) || this.state.fields.finalAmount.match(/^[a-zA-Z ]*$/) || this.state.fields.coveramount === '' || this.state.fields.finalAmount === '' ?
                                null
                                :
                                <Button className="link btn btn-success" onClick={dispatch('personal', 'personalDetails')}>Next</Button>
                              }

                            </div>
                          </div>
                        )
                      }
                    ],
                    personal: [
                      {
                        id: 'personalDetails',
                        component: (
                          <div>
                            <div className="section-wrapper">
                              <div className="header">
                                <h3 className='text-center'>Personal Details</h3>
                                <FormGroup>
                                  <Label for="exampleEmail">Name</Label>
                                  <Input type="message" name="username" value={this.state.fields.username} onChange={this.handleChange}></Input>
                                </FormGroup>

                                <FormGroup>
                                  <Label for="exampleEmail">Email ID</Label>
                                  <Input type="email" name="emailid" value={this.state.fields.emailid} onChange={this.handleChange}></Input>
                                </FormGroup>

                                <FormGroup>
                                  <Label for="exampleEmail">Mobile No</Label>
                                  <Input type="message" name="mobileno" value={this.state.fields.mobileno} onChange={this.handleChange}></Input>
                                </FormGroup>
                              </div>
                            </div>
                            <div className="link">
                              <Button className="btn btn-success" onClick={dispatch('root', 'incomeDetails', true)}>Back</Button>
                              {this.state.fields.username.match(/^[0-9]+$/) || this.state.fields.emailid.match(/^([\w.%+-]+)@([\w-]+\.)$/) || this.state.fields.mobileno.match(/^[a-zA-Z ]*$/) || this.state.fields.username === '' || this.state.fields.emailid === '' || this.state.fields.mobileno === '' ?
                                null
                                :
                                <Button className="pull-right btn btn-success" onClick={dispatch('insurance_plan', 'plan_details')}>Next</Button>
                              }

                            </div>
                          </div>
                        )
                      }
                    ],
                    insurance_plan: [
                      {
                        id: 'plan_details',
                        component: (
                          <div>
                            <div className='form-body'>
                              <div className="header">
                                <h3 className='text-center'>Plan Details</h3>
                                <FormGroup>
                                  <Label for="exampleCheckbox">Insurance Plan</Label>
                                  <div>
                                    <CustomInput type="radio" id="exampleCustomRadio5" name="plan" label="Individual" value="Individual" onChange={this.changeTitleAndHideFields} checked={this.state.fields.plan === 'Individual'} inline />
                                    <CustomInput type="radio" id="exampleCustomRadio6" name="plan" label="Family" value="Family" onChange={this.changeTitleAndHideFields} checked={this.state.fields.plan === 'Family'} inline />
                                  </div>
                                </FormGroup>
                              </div>
                            </div>
                            <div className="link">
                              <Button className="btn btn-success" onClick={dispatch('personal', 'personalDetails', true)}>Back</Button>
                              <Button className="pull-right btn btn-success" onClick={dispatch('age', 'person_age')}>Next</Button>
                            </div>
                          </div>
                        )
                      }
                    ],
                    age: [
                      {
                        id: 'person_age',
                        component: (
                          <div>
                            <div className='form-body'>
                              <div className="header">
                                <h3 className='text-center'>Age Details</h3>
                                <FormGroup>
                                  <Label for="exampleCheckbox">{this.state.title}</Label>
                                  <div className="range-slider text-center">
                                    <input className="range-slider__range" type="range" min="18" max="70" name="age" onChange={this.handleChange} value={this.state.fields.age} />
                                    <input type="message" onChange={this.handleAgeChange.bind(this)} value={this.state.fields.age} className="range-slider__value" /><span> Years</span>
                                  </div>
                                </FormGroup>
                              </div>
                            </div>
                            <div className="link">
                              <Button className="btn btn-success" onClick={dispatch('insurance_plan', 'plan_details', true)}>Back</Button>
                              {(this.state.fields.plan === 'Family') ? this.state.fields.age.match(/^[a-zA-Z ]*$/) || this.state.fields.age === '' ?
                                null
                                :
                                <Button className="pull-right btn btn-success" onClick={dispatch('comment', 'any_comment')}>Next</Button>
                                :
                                <Button className="pull-right btn btn-success" onClick={dispatch('gender', 'gender_details')}>Next</Button>
                              }
                            </div>
                          </div>
                        )
                      }
                    ],
                    gender: [
                      {
                        id: 'gender_details',
                        component: (
                          <div>
                            <div className='form-body'>
                              <div className="header">
                                <h3 className='text-center'>Gender Details</h3>
                                <FormGroup hidden={this.state.hide}>
                                  <Label for="exampleCheckbox">Gender</Label>
                                  <div>
                                    <CustomInput type="radio" id="exampleCustomRadio" name="gender" label="Male" value="Male" onChange={this.handleChange}
                                      checked={this.state.fields.gender === 'Male'} inline />
                                    <CustomInput type="radio" id="exampleCustomRadio2" name="gender" label="Female" value="Female" onChange={this.handleChange} checked={this.state.fields.gender === 'Female'} inline />
                                  </div>
                                </FormGroup>
                              </div>
                            </div>
                            <div className="link">
                              <Button className="btn btn-success" onClick={dispatch('age', 'person_age', true)}>Back</Button>
                              <Button className="pull-right btn btn-success" onClick={dispatch('smoke', 'do_you_smoke')}>Next</Button>
                            </div>
                          </div>
                        )
                      }
                    ],
                    smoke: [
                      {
                        id: 'do_you_smoke',
                        component: (
                          <div>
                            <div className='form-body'>
                              <div className="header">
                                <h3 className='text-center'>Do You Smoke?</h3>
                                <FormGroup hidden={this.state.hide}>
                                  <div>
                                    <CustomInput type="radio" id="exampleCustomRadio3" name="smoke" label="Yes" value="Yes"
                                      checked={this.state.fields.smoke === 'Yes'}
                                      onChange={this.handleChange} inline />
                                    <CustomInput type="radio" id="exampleCustomRadio4" name="smoke" label="No" value="No"
                                      checked={this.state.fields.smoke === 'No'}
                                      onChange={this.handleChange} inline />
                                  </div>
                                </FormGroup>
                              </div>
                            </div>
                            <div className="link">
                              <Button className="btn btn-success" onClick={dispatch('gender', 'gender_details', true)}>Back</Button>
                              <Button className="pull-right btn btn-success" onClick={dispatch('comment', 'any_comment')}>Next</Button>
                            </div>
                          </div>
                        )
                      }
                    ],
                    comment: [
                      {
                        id: 'any_comment',
                        component: (
                          <div>
                            <div className='form-body'>
                              <div className="header">
                                <FormGroup>
                                  <Label for="exampleEmail">Comment</Label>
                                  <Input type="message" name="comment" value={this.state.fields.comment} onChange={this.handleChange} style={{ 'height': '130px' }}></Input>
                                </FormGroup>
                              </div>
                            </div>
                            <div className="link">
                              {(this.state.fields.plan === 'Family') ? <Button className="btn btn-success" onClick={dispatch('age', 'person_age', true)}>Back</Button>
                                :
                                <Button className="btn btn-success" onClick={dispatch('smoke', 'do_you_smoke', true)}>Back</Button>
                              }

                              <Button className="pull-right btn btn-success" onClick={this.submituserRegistrationForm}>Send</Button>
                            </div>
                          </div>
                        )
                      }
                    ]
                  };
                }}
              />
            </div>
          </div>
        </div>
        <NotificationSystem ref="notificationSystem" style={style} />
        <br />
      </div>
    </div>



  );
}


}